import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TCDevice } from 'src/app/state';
import { AbstractFormComponent } from 'src/app/components';
import { Observable } from 'rxjs';
import { TCGroup, GroupsQuery } from 'src/app/state/groups';


@Component({
    selector: 'app-device-form',
    templateUrl: './device-form.component.html',
    styleUrls: ['./device-form.component.scss']
})
export class DeviceFormComponent extends AbstractFormComponent<DeviceFormComponent, TCDevice> {

    public groups$: Observable<TCGroup[]>;
    public categories = [{id:'person', name:'Person'}, {id:'car', name:'Car'}];

    constructor(public override dialogRef: MatDialogRef<DeviceFormComponent>, @Inject(MAT_DIALOG_DATA) public data: TCDevice, protected groupsQ: GroupsQuery) {
        super(dialogRef, data);

        this.groups$ = groupsQ.selectAll();
    }

    protected initForm(fb: FormBuilder): FormGroup {
        const f = fb.group({
            id: '',
            name: ['', [Validators.required]],
            groupId: ['', [Validators.required]],
            uniqueId: ['', [Validators.required]],
            category: ['', [Validators.required]],
            phone: ''
        });

        return f;
    }

}
