import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TayaracMaterialModule } from './tayarac-material/tayarac-material.module';
import { TableModule } from 'ngx-easy-table';
import { MapComponent } from './map/map.component';
import { Fod2NavComponent } from './fod2-nav/fod2-nav.component';
import { LoginComponent } from './login/login.component';
import { MapEditorComponent } from './map-editor/map-editor.component';
import { HomeComponent } from './home/home.component';
import { DeviceSelectComponent } from './device-select/device-select.component';
import { MapBoardComponent } from './map-board/map-board.component';
import { VisitorFormComponent } from './visitors/visitor-form/visitor-form.component';
import {
  DeviceFormComponent,
  DevicesListComponent,
  DevicesOverviewComponent,
} from './devices';
import { VisitsListComponent } from './visits/visits-list/visits-list.component';
import { EventsListComponent } from './events/events-list/events-list.component';
import { wsBaseURL, ServiceLocator, wsTimeout } from './services';
import { tcBaseURL } from './trac-car';

import localeNl from '@angular/common/locales/nl';
import localeBg from '@angular/common/locales/bg';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';

import { environment } from '../environments/environment';
import { HttpXSRFInterceptor } from './utils/http-xsrf-interceptor';
import { BasicAuthInterceptor } from './utils/basic-auth.interceptor';
import { ErrorInterceptor } from './utils/error.interceptor';
import { DevicesQuery } from './state';
import { AuthGuard } from './utils/auth-guard';
import { CookieService } from 'ngx-cookie-service';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { registerLocaleData } from '@angular/common';
import { VisitsOverviewComponent } from './visits/visits-overview/visits-overview.component';
import { ReportComponent } from './reports/report/report.component';
import { VisitInfoComponent } from './visits/visit-info/visit-info.component';
import { ToastModule } from './toast/toast.module';

const SERVER_IP = window.location.hostname;
const HTTP_PROT = window.location.protocol;
const HTTP_PORT = window.location.port;
const SERVER_PATH = environment.appPath;

registerLocaleData(localeNl);
registerLocaleData(localeBg);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    Fod2NavComponent,
    LoginComponent,
    MapEditorComponent,
    HomeComponent,
    DeviceSelectComponent,
    MapBoardComponent,
    VisitorFormComponent,
    DeviceFormComponent,
    DevicesListComponent,
    VisitsListComponent,
    DevicesOverviewComponent,
    EventsListComponent,
    VisitsOverviewComponent,
    ReportComponent,
    VisitInfoComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    AppRoutingModule,
    // LeafletModule.forRoot(),
    // LeafletDrawModule.forRoot(),
    LeafletModule,
    LeafletDrawModule,
    TayaracMaterialModule,
    TableModule,
    NgbModule,
    ToastModule,
  ],
  providers: [
    {
      provide: tcBaseURL,
      useValue:
          // 'http://localhost:8082/api'
        HTTP_PORT !== '4200'
          ? HTTP_PROT + '//' + SERVER_IP + SERVER_PATH
          : 'http://' + SERVER_IP + ':4200' + SERVER_PATH,
    },
    {
      provide: wsBaseURL,
      useValue:
      // 'http://localhost:8082/api/socket'
        HTTP_PORT !== '4200'
          ? HTTP_PROT + '//' + SERVER_IP + SERVER_PATH + '/socket'
          : 'http://' + SERVER_IP + ':4200' + SERVER_PATH + '/socket',
    },
    { provide: wsTimeout, useValue: 5000 },
    { provide: LOCALE_ID, useValue: 'nl_BE' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'nl' },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'DevicesQueryInj', useClass: DevicesQuery },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
