import { DriversStore } from './drivers.store';
import { Injectable } from '@angular/core';
import { TracCarAbstractRestService } from 'src/app/trac-car/trac-car.abstract.rest.service';
import { TCDriver } from './driver.model';
import { HttpParams } from '@angular/common/http';
import { addHttpParam } from 'src/app/utils';
import { Observable, of as observableOf } from 'rxjs';
import { tap, map, flatMap, mergeMap } from 'rxjs/operators';
import { isNil } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
class DriversRestService extends TracCarAbstractRestService<TCDriver> {
  constructor() {
    super();
  }

  protected get url(): string {
    return '/drivers';
  }
}

@Injectable({ providedIn: 'root' })
export class DriversService {
  constructor(
    private driversStore: DriversStore,
    private rest: DriversRestService
  ) {}

  public load(
    deviceId?: number,
    groupId?: number,
    userId?: number,
    refresh?: boolean,
    all?: boolean
  ): Observable<TCDriver[]> {
    let params = new HttpParams();
    params = addHttpParam(params, 'deviceId', deviceId);
    params = addHttpParam(params, 'groupId', groupId);
    params = addHttpParam(params, 'userId', userId);
    params = addHttpParam(params, 'refresh', refresh);
    params = addHttpParam(params, 'all', all);
    return this.rest.read({ params: params }).pipe(
      tap((pos) => {
        this.driversStore.set(pos);
      })
    );
  }

  public save(driver: TCDriver, groupId?: number): Observable<TCDriver> {
    const obs$ = !driver.id
      ? this.rest.create(driver)
      : this.rest.update(driver);
    return obs$.pipe(
      mergeMap((d) => {
        if (!!groupId) {
          return this.rest
            .createPermission({ groupId: groupId, driverId: d.id })
            .pipe(map((_) => d));
        }

        return observableOf(d);
      }),
      tap((d) => {
        this.driversStore.upsert(d.id, d);
      })
    );
  }

  delete(id: number): any {
    return this.rest.erase(id).pipe(tap((_) => this.driversStore.remove(id)));
  }

  public upsertMany(drivers: TCDriver[]) {
    this.driversStore.upsertMany(drivers);
  }
}
