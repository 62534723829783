import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';
import { TCVisitFull } from './visit.model';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

export interface VisitFilter {
  selectedDate?: moment.Moment;
  deviceName?: string;
  visitorName?: string;
}

export interface VisitsState extends EntityState<TCVisitFull>, ActiveState {
  filter: VisitFilter;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'visits' })
export class VisitsStore extends EntityStore<VisitsState> {
  constructor() {
    super({ filter: {} });
  }
}
