import { GeofencesStore } from './geofences.store';
import { Injectable } from '@angular/core';
import { TCGeofence } from './geofence.model';
import { TracCarAbstractRestService } from 'src/app/trac-car/trac-car.abstract.rest.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { addHttpParam } from 'src/app/utils';

@Injectable( { providedIn: 'root' } )
class GeofencesRestService extends TracCarAbstractRestService<TCGeofence> {

    constructor() {
        super();
    }

    protected get url(): string {
        return '/geofences';
    }
}



@Injectable( { providedIn: 'root' } )
export class GeofencesService {

    constructor( private geofencesStore: GeofencesStore, private rest: GeofencesRestService ) {
    }


    public load( deviceId?: number, groupId?: number, userId?: number, refresh?: boolean, all?: boolean ): Observable<TCGeofence[]> {

        let params = new HttpParams();
        params = addHttpParam( params, 'deviceId', deviceId );
        params = addHttpParam( params, 'groupId', groupId );
        params = addHttpParam( params, 'userId', userId );
        params = addHttpParam( params, 'refresh', refresh );
        params = addHttpParam( params, 'all', all );
        return this.rest.read( { params: params } ).pipe(
            tap( gfs => {
                this.geofencesStore.set( gfs );
            } )
        );
    }
}
