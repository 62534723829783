
export interface TCEvent {
  id: number;
  type: string;
  eventTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
  deviceId: number;
  positionId: number;
  geofenceId: number;
  maintenanceId: number;
  attributes: any;
}

export function createEvent(params: Partial<TCEvent>) {
  return {
    ...params
  } as TCEvent;
}
