import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MapBoardComponent } from './map-board/map-board.component';
import { ReportComponent } from './reports/report/report.component';
import { AuthGuard } from './utils/auth-guard';

const routes: Routes = [
    { path: '', redirectTo: '/map', pathMatch: 'full' },
    { path: 'home', redirectTo: '/map', pathMatch: 'full' }, //component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'map', component: MapBoardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'reports', component: ReportComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'devices', redirectTo: '/map', pathMatch: 'full' }, //component: DevicesOverviewComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'test', redirectTo: '/map', pathMatch: 'full' } //component: MapEditorComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
