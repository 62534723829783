<div fxLayout="column" class="report-board">
  <header>
    <h1>Visitor sessions</h1>
    <h3>{{ reportDate | date: "longDate":locale }}</h3>
  </header>

  <main>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5em" class="filter">
      <div>
        Selected date: <input
          class="calendar-input"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          (dateTimeInput)="onDateSearch($event.value)"
          matTooltip="Filter by date"
          [(ngModel)]="reportDate"
        />
        <span class="trigger" [owlDateTimeTrigger]="dt1">
          <i class="fas fa-calendar-alt"></i>
        </span>
        <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
      </div>
      <div>
        <label class="form-switch">
          <input type="checkbox" [checked]="toggled" (click)="toggleRows()" />
          <i class="form-icon"></i> Show details
        </label>
      </div>
    </div>

    <ngx-table
      [id]="'reportList'"
      [configuration]="configuration"
      [data]="rows$ | async"
      [columns]="columns"
      [detailsTemplate]="detailsTemplate"
    ></ngx-table>
  </main>
</div>

<ng-template #startTimeColumn let-row>
  <div>{{ row.visit?.startTime | date: "medium":locale }}</div>
</ng-template>

<ng-template #endTimeColumn let-row>
  <div>{{ row.visit?.endTime | date: "medium":locale }}</div>
</ng-template>

<ng-template #eventTimeColumn let-row>
  <div>{{ row.eventTime | date: "medium":locale }}</div>
</ng-template>

<ng-template #alarmsColumn let-row>
  <div>{{ row.events?.length }}</div>
</ng-template>

<ng-template #detailsTemplate let-row>
  <table class="events-table" *ngIf="row.visit?.events.length > 0">
    <thead>
      <tr>
        <th>Event time</th>
        <th>Event type</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ev of row.events" class="event-row">
        <td class="event-time event-cell">
          {{ ev.eventTime | date: "medium":locale }}
        </td>
        <td class="evet-type event-cell">{{ ev.type }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
