import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TCGeofence } from './geofence.model';
import { Injectable } from '@angular/core';

export interface GeofencesState extends EntityState<TCGeofence> {}

@Injectable( { providedIn: 'root' } )
@StoreConfig({ name: 'geofences' })
export class GeofencesStore extends EntityStore<GeofencesState> {

  constructor() {
    super();
  }

}

