import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TCEvent } from './event.model';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

export interface EventFilter {
  selectedDate?: moment.Moment;
  deviceName?: string;
  type?: string;
  visitorName?: string;
}

export interface EventsState extends EntityState<TCEvent>, ActiveState {
  filter: EventFilter;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'events' })
export class EventsStore extends EntityStore<EventsState> {

  constructor() {
    super({ filter: {} });
  }
}

