import { Icon } from 'leaflet';

export const HAIRCROSS =
    '<svg' +
    '   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"' +
    '   xmlns:svg="http://www.w3.org/2000/svg"' +
    '   xmlns="http://www.w3.org/2000/svg"' +
    '   width="40"' +
    '   height="40"' +
    '   viewBox="0 0 10.583333 10.583333"' +
    '   version="1.1"' +
    '   id="svg8">' +
    '  <g' +
    '     id="layer1"' +
    '     transform="translate(0,-286.41667)">' +
    '    <circle' +
    '       style="fill:${color};fill-opacity:0.5;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.8;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
    '       id="path4518"' +
    '       cx="5.2916656"' +
    '       cy="291.70834"' +
    '       r="3.9148402" />' +
    '    <rect' +
    '       style="fill:#0000ff;fill-opacity:1;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.35692596;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
    '       id="rect4520"' +
    '       width="0.37687847"' +
    '       height="1.8834252"' +
    '       x="5.1032267"' +
    '       y="286.79309" />' +
    '    <rect' +
    '       y="294.74014"' +
    '       x="5.1032267"' +
    '       height="1.8834252"' +
    '       width="0.37687847"' +
    '       id="rect4522"' +
    '       style="fill:#0000ff;fill-opacity:1;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.35692596;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />' +
    '    <rect' +
    '       y="-10.206896"' +
    '       x="291.5199"' +
    '       height="1.8834252"' +
    '       width="0.37687847"' +
    '       id="rect4528"' +
    '       style="fill:#0000ff;fill-opacity:1;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.35692596;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
    '       transform="rotate(90)" />' +
    '    <rect' +
    '       style="fill:#0000ff;fill-opacity:1;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.35692596;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
    '       id="rect4530"' +
    '       width="0.37687847"' +
    '       height="1.8834252"' +
    '       x="291.5199"' +
    '       y="-2.2598629"' +
    '       transform="rotate(90)" />' +
    '    <circle' +
    '       style="fill:#0000ff;fill-opacity:1;fill-rule:nonzero;stroke:#0000ff;stroke-width:0.28184038;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
    '       id="path4543"' +
    '       cx="5.2916665"' +
    '       cy="291.70834"' +
    '       r="0.60261875" />' +
    '  </g>' +
    '</svg>';

export const redHairCross = new Icon(
    {
        iconUrl: 'data:image/svg+xml;base64,' + btoa(HAIRCROSS.replace('${color}', 'red')),
        iconSize: [40, 40],
        shadowSize: [40, 40],
        iconAnchor: [20, 20],
        shadowAnchor: [0, 0],
        popupAnchor: [20, -20]
    }
);

export const blueHairCross = new Icon(
    {
        iconUrl: 'data:image/svg+xml;base64,' + btoa(HAIRCROSS.replace('${color}', 'blue')),
        iconSize: [40, 40],
        shadowSize: [40, 40],
        iconAnchor: [20, 20],
        shadowAnchor: [0, 0],
        popupAnchor: [20, -20]
    }
);
