import { Observable, zip, range, timer } from "rxjs";
import { repeatWhen, flatMap } from "rxjs/operators";

export class BackoffPolicy {

    constructor( private retries: number ) { };

    public static of( retries: number ): BackoffPolicy {
        return new BackoffPolicy( retries );
    }

    public do<T>( obs$: Observable<T> ): Observable<T> {
        console.log( "Backoff.do");
        return obs$.pipe(
            repeatWhen( errors => {
                console.log('xxxx');
                return zip( errors, range( 1, this.retries ), ( n, i ) => i ).pipe(
                    flatMap( retryCount => {
                        console.log( 'Retrying ', retryCount, ' time because' );
                        return timer( Math.pow( 2, retryCount ) * 1000 );
                    } )
                );
            })
        );
    }
}