import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TCDevice } from './device.model';
import { SetEntities } from '@datorama/akita/src/lib/setEntities';

export interface DevicesState extends EntityState<TCDevice>, ActiveState { }

@Injectable( { providedIn: 'root' } )
@StoreConfig( { name: 'devices' } )
export class DevicesStore extends EntityStore<DevicesState> {

    constructor() {
        super();
    }
}

