import { TCGeofence } from './../state/geofences/geofence.model';
import { TCPosition } from './../state/positions/position.model';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { isNil, coerceArray } from '@datorama/akita';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { ConfirmationDialog } from '../tayarac-material/confirmation-dialog/confirmation-dialog.component';
import { TCEvent, TCVisitFull } from '../state';
import { environment } from 'src/environments/environment';

export function addHttpParam<T>(
  org: HttpParams,
  name: string,
  data: T
): HttpParams {
  const value: any = data;
  // if (name === 'groupId' && isNil(value)) {
  //     value = 1;
  // }

  if (isNil(value)) {
    return org;
  }

  coerceArray(value).forEach((i) => (org = org.append(name, `${i}`)));
  return org;
}

export function dateReviver(key: string, value: any): any {
  if (typeof value === 'string') {
    // "2017-12-21T14:24:32.716+0000"
    const a =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?).*$/.exec(
        value
      );
    if (a) {
      const m = moment(value);
      const d = m.toDate();
      return d;
      //            return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
      // return new Date(value);
    }
  }
  return value;
}

export function clone<Item>(o: Item): Item {
  return JSON.parse(JSON.stringify(o), dateReviver);
}

function minDate(a: Date, b: Date): Date {
  if (!a) {
    return b;
  }
  if (!b) {
    return a;
  }
  return a <= b ? a : b;
}

function maxDate(a: Date, b: Date): Date {
  if (!a) {
    return b;
  }
  if (!b) {
    return a;
  }
  return a >= b ? a : b;
}

function dateInterval(a: Date, b: Date): boolean {
  if (!a || !b) {
    return true;
  }
  return a <= b;
}

export function splitOnCapitals(s: String) {
  if (!s) return s;
  const x = s.split(/(?=[A-Z])/);
  const r = x.join(' ');
  return r;
}

export function capitalizeFirstLetter(s: string) {
  if (!s) return s;
  const [first, ...rest] = s;
  const res = first.toUpperCase() + rest.join('');
  return res;
}

export function localeCapitalizeFirstLetter(s: string, locale: string) {
  if (!s) return s;
  const [first, ...rest] = s;
  const res = first.toLocaleUpperCase(locale) + rest.join('');
  return res;
}

export function sanitizeError(err: string | Error): string {
  if (err instanceof Error) {
    console.error(err.message, err);
    err = err.message;
  }

  if (!err) {
    err = 'Generic error';
  }

  // Error: GraphQL error:
  if (err.startsWith('Error: ', 0)) {
    err = err.substr('Error: '.length);
  }
  if (err.startsWith('GraphQL error: ', 0)) {
    err = err.substr('GraphQL error: '.length);
  }

  const pos = err.lastIndexOf('Exception:');
  return pos < 0
    ? err
    : err.substr(err.lastIndexOf('Exception:') + 'Exception:'.length);
}

export class ConfirmationService {
  static dialog: MatDialog;
}

export function confirm(
  componentInstance: any,
  title: string,
  message: string,
  onYes?: () => void,
  onCancel?: () => void
): void {
  const dialogRef = ConfirmationService.dialog.open(ConfirmationDialog, {
    width: 'auto',
    maxWidth: '60vw',
    data: {
      title: title,
      message: message,
    },
  });

  dialogRef
    .afterClosed()
    .pipe(
      untilDestroyed(componentInstance),
      map((result) => true === result)
    )
    .subscribe((result) => {
      if (result) {
        if (!!onYes) {
          onYes();
        }
      } else {
        if (!!onCancel) {
          onCancel();
        }
      }
    });
}

export function arrayEquals<T>(
  a: T[],
  b: T[],
  comp: (a: T, b: T) => boolean = (a: T, b: T) => a === b
): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => comp(val, b[index]))
  );
}

export function visitDateComparator(a: TCVisitFull, b: TCVisitFull): number {
  if (!a.endTime && !b.endTime) {
    return a.startTime < b.startTime ? -1 : 1;
  }
  if (!a.endTime) {
    return -1;
  }
  if (!b.endTime) {
    return 1;
  }

  return a.endTime < b.endTime ? -1 : 1;
}

export function positionsTimeComparator(a: TCPosition, b: TCPosition): number {
  if (a.fixTime && b.fixTime) {
    return a.fixTime < b.fixTime ? -1 : 1;
  }
  if (!a.fixTime) {
    return -1;
  }
  if (!b.fixTime) {
    return 1;
  }
  return 0;
}

export const ALARM_TYPES = [
  'alarm',
  'deviceOffline',
  'deviceStopped',
  'geofenceEnter',
  'geofenceExit',
]; //,'deviceMoving', 'deviceOnline', 'deviceUnknown'],

export function onlyAlarmTypeEvents(
  events: TCEvent[],
  geoFences: TCGeofence[],
  alarmTypes: string[] | false = ALARM_TYPES
): TCEvent[] {
  if (!alarmTypes) return events;

  const types = alarmTypes.map((t) => t.toLowerCase());
  return events.filter((ev) => {
    var res = types.some((t) => ev.type?.toLowerCase() === t);
    if (!res) return res;

    if (ev.type?.toLowerCase() === 'geofenceenter') {
      const gf = geoFences.find((g) => g.id === ev.geofenceId);
      const forbidden = isGeoFenceForbidden(gf);
      return forbidden;
    }

    if (ev.type?.toLowerCase() === 'geofenceexit') {
      const gf = geoFences.find((g) => g.id === ev.geofenceId);
      const permitted = isGeoFencePermitted(gf);
      return permitted;
    }

    return res;
  });
}

export function isGeoFenceForbidden(geoFence: TCGeofence | undefined): boolean {
  if (!geoFence) return false;
  return geoFence.attributes?.Access === 'forbidden';
}

export function isGeoFencePermitted(geoFence: TCGeofence | undefined): boolean {
  if (!geoFence) return false;
  return geoFence.attributes?.Access === 'permitted';
}

export function onlyDistinctPositions(pos: TCPosition[]): TCPosition[] {
  const res = arrayDistinct(
    pos,
    (x, v) => {
      return (
        x.deviceId === v.deviceId &&
        x.fixTime === v.fixTime &&
        x.latitude === v.latitude &&
        x.longitude === v.longitude
      );
    },
    (x, v) => {
      return x?.id === v?.id;
    }
  );

  return res;
}

export function arrayDistinct<T>(
  pos: T[],
  comp: (a: T, b: T) => boolean,
  comp2: (a?: T, b?: T) => boolean = (a, b) => a === b
): T[] {
  const res = pos.filter((v, i, ar) => {
    const ar1 = ar.slice(i+1);
    const z = ar1.find((x) => comp(x, v));

    return !z || !comp2(z, v);
  });

  return res;
}

export function playAudio() {
  try {
    let audio = new Audio();
    audio.src = environment.assetPath + '/audio/alarm.wav';
    audio.load();
    audio.play();
  } catch (ex) {
    console.log(`Error playing sound: ${ex}`);
  }
}
