import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  Observable,
  tap,
  mergeMap,
  EMPTY,
  switchMap,
  catchError,
  throwError,
} from 'rxjs';
import { VisitorFormComponent } from '../../visitors/visitor-form/visitor-form.component';
import { ServiceLocator } from 'src/app/services';
import {
  createVisit,
  DevicesQuery,
  DevicesService,
  DriversService,
  PositionsService,
  TCDriverWithDevice,
  TCVisitFull,
  VisitsQuery,
  VisitsService,
} from 'src/app/state';
import { sanitizeError, confirm } from 'src/app/utils';
import { GroupsQuery, TCGroup } from 'src/app/state/groups';
import * as moment from 'moment';
import { MapComponent } from 'src/app/map/map.component';

@Component({
  selector: 'app-visits-overview',
  templateUrl: './visits-overview.component.html',
  styleUrls: ['./visits-overview.component.scss'],
})
export class VisitsOverviewComponent implements OnInit, OnDestroy {
  protected snackBar: MatSnackBar;
  protected dialog: MatDialog;

  public selectedVisit?: TCVisitFull;

  @Input()
  public map!: MapComponent;

  @Output()
  public visitSelected = new EventEmitter<TCVisitFull>();

  constructor(
    protected driversSvc: DriversService,
    protected devicesSvc: DevicesService,
    protected devicesQuery: DevicesQuery,
    protected visitsSvc: VisitsService,
    protected visitsQuery: VisitsQuery,
    protected groupsQuery: GroupsQuery,
    protected positionSvc: PositionsService
  ) {
    this.snackBar = ServiceLocator.injector.get(MatSnackBar);
    this.dialog = ServiceLocator.injector.get(MatDialog);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  public addVisitor(): void {
    const groups = this.groupsQuery.getActive();
    const group: TCGroup | undefined = Array.isArray(groups)
      ? groups.length > 0
        ? groups[0]
        : undefined
      : groups;

    if (isNil(group)) {
      return;
    }

    const dialogRef = this.dialog.open(VisitorFormComponent, {
      width: 'auto',
      data: null,
    });

    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((person: TCDriverWithDevice) => {
          if (isNil(person)) {
            return EMPTY;
          }
          console.log('Create new visitor: ', person);
          const { deviceId, ...toSave } = person;
          const obs$ = this.driversSvc.save(toSave, group!.id).pipe(
            switchMap((visitor) =>
              this.visitsSvc.startVisit(deviceId, visitor.id, group!.id).pipe(
                catchError((err) => {
                  return this.driversSvc.delete(visitor.id).pipe(
                    switchMap((_) => {
                      return throwError(() => err);
                    })
                  );
                })
              )
            )
          );

          return obs$;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (visit: any) => {
          console.log(`The visit ${visit?.id} has started`);
        },
        error: (e: any) => {
          if (!isNil(e)) {
            this.snackBar.open(sanitizeError(e), undefined, {
              duration: 5000,
              panelClass: 'snackbar-error',
            });
          }
        },
      });
  }

  public endVisit(visit: any) {
    confirm(
      this,
      `Confirmation`,
      `Are you sure you want to end the visit of ${visit.visitor?.name}`,
      () => {
        this.visitsSvc
          .endVisit(visit.id)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (v) => {
              console.log(`The visit ${v.id} has ended at ${v.endTime}`);
            },
            error: (e) => {
              if (!isNil(e)) {
                this.snackBar.open(sanitizeError(e), undefined, {
                  duration: 5000,
                  panelClass: 'snackbar-error',
                });
              }
            },
          });
      }
    );
  }

  public onVisitSelected(visit: TCVisitFull) {
    this.selectedVisit = (this.selectedVisit?.id === visit?.id) ? undefined : visit;
    this.visitSelected.emit(this.selectedVisit);
  }
}
