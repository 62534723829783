import { GroupsStore } from './groups.store';
import { Injectable } from '@angular/core';
import { TCGroup } from './group.model';
import { TracCarAbstractRestService } from 'src/app/trac-car/trac-car.abstract.rest.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { addHttpParam } from 'src/app/utils';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GroupsRestService extends TracCarAbstractRestService<TCGroup> {

    constructor() {
        super();
    }

    protected get url(): string {
        return '/groups';
    }
}

@Injectable({ providedIn: 'root' })
export class GroupsService {

    constructor(private groupsStore: GroupsStore, private rest: GroupsRestService) {
    }

    public load(userId?: number, all?: boolean): Observable<TCGroup[]> {

        let params = new HttpParams();
        params = addHttpParam(params, 'userId', userId);
        params = addHttpParam(params, 'all', all);
        return this.rest.read({ params: params }).pipe(
            tap(groups => {
                this.groupsStore.set(groups);
                if (Array.isArray(groups) && groups.length > 0) {
                    this.groupsStore.setActive(groups[0].id);
                }
            })
        );
    }
}
