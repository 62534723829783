import {
  ALARM_TYPES,
  arrayDistinct,
  capitalizeFirstLetter,
  splitOnCapitals,
} from './../../utils/utils';
import { QueryEntity, isNil } from '@datorama/akita';
import { EventsStore, EventsState } from './events.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DevicesQuery } from '../devices/devices.query';
import { TCEvent } from './event.model';
import { TCDriver } from '../drivers/driver.model';
import { TCDevice } from '../devices/device.model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DriversQuery } from '../drivers/drivers.query';
import { VisitsQuery } from '../visits/visits.query';
import { TCVisit } from '../visits/visit.model';
import { onlyAlarmTypeEvents } from 'src/app/utils/utils';
import { GeofencesQuery } from '../geofences';

export type EventWithVisitorDeviceVisit = TCEvent & {
  visitor?: TCDriver;
  device?: TCDevice;
  visit?: TCVisit;
};

@Injectable({ providedIn: 'root' })
export class EventsQuery extends QueryEntity<EventsState> {
  constructor(
    protected override store: EventsStore,
    protected driversQuery: DriversQuery,
    protected visitsQuery: VisitsQuery,
    protected devicesQuery: DevicesQuery,
    private geoFencQuery: GeofencesQuery
  ) {
    super(store);
  }

  public selectFiltered(
    types: string[] | false = ALARM_TYPES
  ): Observable<TCEvent[]> {
    const devices$ = this.devicesQuery.selectAll();
    const drivers$ = this.driversQuery.selectAll();
    const visits$ = this.visitsQuery.selectAll();
    const geoFences$ = this.geoFencQuery.selectAll();
    const events$ = combineLatest([this.selectAll(), geoFences$]).pipe(
      map(([es, gfs]) => {
        return onlyAlarmTypeEvents(es, gfs, types);
      }),
      map((es) => {
        return es.map((e) => {
          const type =
            'alarm' === e.type && e.attributes[e.type]
              ? `${e.attributes[e.type]}`
              : `${e.type}`;
          return {
            ...e,
            type: splitOnCapitals(capitalizeFirstLetter(type)),
          };
        });
      })
    );

    return combineLatest([
      this.select((st) => st.filter),
      events$,
      drivers$,
      visits$,
      devices$,
    ]).pipe(
      map(([f, events, dr, visits, dev]) => {
        // if (isNil(f)) {
        //   return events;
        // }

        let drivers: TCDriver[];
        if (isNil(f?.visitorName)) {
          drivers = dr;
        } else {
          const visitorName = f.visitorName!.toLowerCase();
          drivers = dr.filter((d) => {
            return -1 !== d.name.toLowerCase().indexOf(visitorName);
          });
        }

        let devices: TCDevice[];
        if (isNil(f?.deviceName)) {
          devices = dev;
        } else {
          const deviceName = f.deviceName!.toLowerCase();
          devices = dev.filter((d) => {
            return -1 !== d.name.toLowerCase().indexOf(deviceName);
          });
        }

        const res: EventWithVisitorDeviceVisit[] = events.map((ev) => {
          const device = devices.find((d) => d.id === ev.deviceId);
          const visit = visits.find(
            (v) =>
              v.deviceId === device?.id &&
              v.events.some((ve) => ve.id === ev.id)
          );
          const driver = drivers.find((d) => d.id === visit?.driverId);

          return {
            ...ev,
            device: device,
            visitor: driver,
            visit: visit,
          };
        });

        const eventsToRet = res.filter((ev) => {
          if (!isNil(f?.selectedDate)) {
            const ts = moment(ev.eventTime);
            if (ts.isValid()) {
              if (
                f.selectedDate! < ts.startOf('day') ||
                ts.endOf('day') < f.selectedDate!
              ) {
                return false;
              }
            }
          }

          if (!isNil(f?.type)) {
            const ftype = f.type!.toLowerCase();
            if (-1 === ev.type.toLowerCase().indexOf(ftype)) {
              return false;
            }
          }

          return !!ev.device && !!ev.visit && !!ev.visitor;
        });

        const evIds = arrayDistinct(eventsToRet, (a, b) => a.id === b.id);
        return evIds;
      })
    );
  }
}
