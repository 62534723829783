import { PositionsService } from './../positions/positions.service';
import { PositionsQuery } from './../positions/positions.query';
import { EventsQuery } from './../events/events.query';
import { EventsService, TCPosition } from 'src/app/state';
import { map, mergeMap, take } from 'rxjs/operators';
import { Fod2Store } from './fod2.store';
import { Injectable } from '@angular/core';
import { concat, merge, Observable } from 'rxjs';
import {
  VisitsService,
  VisitsQuery,
  DriversService,
  DevicesService,
  DevicesQuery,
} from '..';
import { GroupsQuery } from '../groups';

@Injectable({ providedIn: 'root' })
export class Fod2Service {
  constructor(
    protected fod2Store: Fod2Store,
    protected visitsSvc: VisitsService,
    private visitsQuery: VisitsQuery,
    protected driversSvc: DriversService,
    protected devicesSvc: DevicesService,
    private devicesQuery: DevicesQuery,
    private eventsSvc: EventsService,
    private eventsQuery: EventsQuery,
    private groupsQuery: GroupsQuery,
    private positionsQuery: PositionsQuery,
    private positionsService: PositionsService
  ) {}

  public reset(): void {
    this.fod2Store.reset();
  }

  public load(): Observable<any> {
    const dv$ = this.devicesSvc.load();

    const gr$ = this.groupsQuery.selectAll().pipe(take(1));

    const dr$ = gr$.pipe(
      mergeMap((gr) => {
        if (Array.isArray(gr) && gr.length > 0) {
          return this.driversSvc.load(undefined, gr[0].id);
        }

        return this.driversSvc.load();
      })
    );
    const visits$ = gr$.pipe(
      mergeMap((gr) => {
        if (Array.isArray(gr) && gr.length > 0) {
          return this.visitsSvc.load(0, gr[0].id, 0, true, false);
        }

        return this.visitsSvc.load();
      })
    );

    return concat(dv$, merge(dr$, visits$));
  }

  public setActiveDevice(deviceId: number | null) {
    const newActive =
      this.devicesQuery.getActiveId() === deviceId ? null : deviceId;
    this.devicesSvc.setActive(newActive);
    if (newActive) {
      this.visitsSvc.setActive(null);
      this.eventsSvc.setActive(null);
    }
  }

  public setActiveVisit(visitId: number | null) {
    const newActive =
      this.visitsQuery.getActiveId() === visitId ? null : visitId;
    this.visitsSvc.setActive(newActive);
    if (newActive) {
      this.devicesSvc.setActive(null);
      this.eventsSvc.setActive(null);
    }
  }

  public setActiveEvent(eventId: number | null) {
    const newActive =
      this.eventsQuery.getActiveId() === eventId ? null : eventId;
    this.eventsSvc.setActive(newActive);

    if (newActive) {
      const visit = this.visitsQuery
        .getAll()
        .find((v) => v.events.some((ev) => ev.id === eventId));
      if (visit) {
        this.visitsSvc.setActive(visit.id);
      }
    }

    // if (newActive) {
    //   this.devicesSvc.setActive(null);
    //   this.visitsSvc.setActive(null);
    // }
  }

  public loadPositions(posIds: number[]): Observable<TCPosition[]> {
    return this.positionsQuery.selectMany(posIds).pipe(
      mergeMap((existing) => {
        const nex = posIds.filter((id) => !existing.some((e) => e.id === id));
        const all$ = this.positionsService
          .load(nex)
          .pipe(map((nex) => [...nex, ...existing]));
        return all$;
      })
    );
  }
}
