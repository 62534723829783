import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { DevicesStore } from '../devices';
import { GeofencesStore } from '../geofences';
import { DriversStore } from '../drivers';
import { EventsStore } from '../events';
import { GroupsStore } from '../groups';
import { PositionsStore } from '../positions';
import { VisitsStore } from '../visits';

export interface Fod2State {
  key: string;
}

export function createInitialState(): Fod2State {
  return {
    key: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fod2' })
export class Fod2Store extends Store<Fod2State> {

  constructor(
    private devicesStore: DevicesStore,
    private driversStore: DriversStore,
    private eventsStore: EventsStore,
    private geofencesStore: GeofencesStore,
    private groupsStore: GroupsStore,
    private positionsStore: PositionsStore,
    private visitsStore: VisitsStore
  ) {
    super(createInitialState());
  }

  override reset(): void {
    super.reset();
    this.devicesStore.reset();
    this.driversStore.reset();
    this.eventsStore.reset();
    this.geofencesStore.reset();
    this.groupsStore.reset();
    this.positionsStore.reset();
    this.visitsStore.reset();
  }
}
