import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpXSRFInterceptor implements HttpInterceptor {

    constructor( private tokenExtractor: HttpXsrfTokenExtractor ) {
    }

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        const headerName = 'XSRF-TOKEN';//'X-CSRF-TOKEN'; //'XSRF-TOKEN';
        const respHeaderName = 'XSRF-TOKEN'; //'X-CSRF-TOKEN';//'X-XSRF-TOKEN';
        let token = this.tokenExtractor.getToken() as string;
        if ( token !== null && !req.headers.has( headerName ) ) {
            req = req.clone( { headers: req.headers.set( respHeaderName, token ) } );
        }
        return next.handle( req ).pipe(
            tap( ev => {
                if ( ev instanceof HttpResponse ) {
                    console.log( "Response: ", ev );
                    let cookies = ( ev as HttpResponse<any> ).headers.getAll( 'set-cookie' );
                    if ( cookies ) {
                        cookies.forEach( h => {
                            console.log( "  -- Header: ", h );
                        } )
                    }
                }
            } )
        );
    }
}