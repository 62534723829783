import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TCPosition } from './position.model';

export interface PositionsState extends EntityState<TCPosition> {}

@Injectable( { providedIn: 'root' } )
@StoreConfig({ name: 'positions' }) // , idKey: 'deviceId' })
export class PositionsStore extends EntityStore<PositionsState> {

  constructor() {
    super();
  }

}

