<ngx-table [id]="'devicesList'" [configuration]="configuration" [data]="(devices$ | async)" [columns]="columnsDef" (event)="onEvent($event)"></ngx-table>


<ng-template #categoryColumn let-row>
    {{capitalizeFirstLetter(row.category)}}
</ng-template>

<ng-template #timeColumn let-row>
    <div>{{row.lastUpdate | date:'medium':locale}}</div>
</ng-template>

<ng-template #actionsColumn let-row>
    <div class="action-cell" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
        <button mat-icon-button color="primary" (click)="onEdit($event, row)" type="button" [disabled]="false" matTooltip="Edit">
            <mat-icon fontSet="fas" fontIcon="fa-cog" class="mat-24"></mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDelete($event, row)" type="button" [disabled]="false"  matTooltip="Remove">
            <mat-icon fontSet="fas" fontIcon="fa-trash" class="mat-24"></mat-icon>
        </button>
    </div>
</ng-template>
    