export class Toast {
  title: string;
  description?: string;
  data?:  any;
  acknowledged: boolean

  constructor( t: string, descr?: string, d?: any) {
      this.title = t;
      this.description = descr;
      this.data = d;
      this.acknowledged = false;
  }
}
