import * as L from 'leaflet';

const iconOptions = {
  iconSize: [30, 50],
  iconAnchor: [15, 50],
  popupAnchor: [2, -40],
  shadowAnchor: [39, 45],
  shadowSize: [54, 51],
  className: 'vector-marker',
  prefix: 'fa',
  spinClass: 'fa-spin',
  extraIconClasses: '',
  extraDivClasses: '',
  icon: 'home',
  markerColor: 'blue',
  iconColor: 'white',
  viewBox: '0 0 32 52',
};

const mapPin = 'M16,1 C7.7146,1 1,7.65636364 1,15.8648485 C1,24.0760606 16,51 16,51 C16,51 31,24.0760606 31,15.8648485 C31,7.65636364 24.2815,1 16,1 L16,1 Z';

export interface MarkersIconOptions extends L.IconOptions {
  map_pin?: string;
  markerColor?: string;
  extraDivClasses?: string;
  viewBox?: string;
  prefix: string;
  extraClasses?: string;
  icon: string;
  spin?: boolean;
  spinClass?: string;
  iconColor?: string;
}

export default class VectorIcon extends L.Icon<MarkersIconOptions> {
  constructor(options: MarkersIconOptions) {
    super(options);
    L.Util.setOptions(this, iconOptions);
    L.Util.setOptions(this, options);
  }

  override createIcon(oldIcon?: HTMLElement) {
    const div = (oldIcon && oldIcon.tagName === 'DIV' ? oldIcon : document.createElement('div'));
    const options = this.options;
    const pin_path = options.map_pin || mapPin;
    const iconSize: L.PointTuple = options.iconSize as L.PointTuple;

    div.innerHTML = `<svg width="${iconSize[0]}px" height="${iconSize[1]}px" viewBox="${options.viewBox}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="${pin_path}" fill="${options.markerColor}"></path></svg>`;

    if (options.icon) {
      div.appendChild(this._createInner());
    }

    options.className += options.className?.length && options.className?.length > 0 ? ' ' + (options.extraDivClasses || '') : (options.extraDivClasses || '');
    this._setIconStyles(div, 'icon');
    this._setIconStyles(div, `icon-${options.markerColor}`);
    return div;
  }

  override createShadow() {
    const div = document.createElement('div');
    this._setIconStyles(div, 'shadow');
    return div;
  }

  _createInner() {
    const i = document.createElement('i');
    const options = this.options;

    i.classList.add(options.prefix);
    if (options.extraClasses) {
      i.classList.add(options.extraClasses);
    }
    if (options.prefix) {
      i.classList.add(options.prefix + '-' + options.icon);
    } else {
      i.classList.add(options.icon);
    }
    if (options.spin && typeof options.spinClass === 'string') {
      i.classList.add(options.spinClass);
    }
    if (options.iconColor) {
      if (options.iconColor === 'white' || options.iconColor === 'black') {
        i.classList.add('icon-' + options.iconColor);
      } else {
        i.style.color = options.iconColor;
      }
    }
    if (options.iconSize) {
      i.style.width = (options.iconSize as L.PointTuple)[0] + 'px';
    }
    return i;
  }

  _setIconStyles(img: HTMLElement, name: string) {
    const options = this.options;
    const size = L.point((name === 'shadow' ? options.shadowSize : options.iconSize) || {x:0, y:0});
    let anchor : L.Point;

    if (name === 'shadow') {
      anchor = L.point(options.shadowAnchor || options.iconAnchor || {x:0, y:0});
    } else {
      anchor = L.point(options.iconAnchor|| {x:0, y:0});
    }
    if (!anchor && size) {
      anchor = size.divideBy(2).round();
    }
    img.className = 'vector-marker-' + name + ' ' + options.className;
    if (anchor) {
      img.style.marginLeft = (-anchor.x) + 'px';
      img.style.marginTop = (-anchor.y) + 'px';
    }
    if (size) {
      img.style.width = size.x + 'px';
      img.style.height = size.y + 'px';
    }
  }
}
