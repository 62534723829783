import { LatLng, Layer, LayerGroup, polygon } from "leaflet";
import { TCGeofence } from "../state";
import { isGeoFenceForbidden } from "../utils";
import { convertPoints } from "./map.utils";


export class GeoFenceDraw {
    
    constructor(private geoFences: LayerGroup) {}

    public refreshGeoFences(geoFences: TCGeofence[]) {
        geoFences = geoFences.sort((a, b) => {
          if (isGeoFenceForbidden(a)) return 1;
          if (isGeoFenceForbidden(b)) return -1;
          return 0;
        });
        const ls: Layer[] = geoFences
          .filter((gf) => {
            const area = gf.area;
            return area.startsWith('POLYGON');
          })
          .map((gf) => {
            const area = gf.area;
            const pts: LatLng[] = convertPoints(
              area.substring(area.indexOf('((') + 2, area.indexOf('))'))
            );
            const x = polygon(pts, gf.attributes);
            return x;
          });
    
        this.geoFences.clearLayers();
        ls.forEach((l) => this.geoFences.addLayer(l));
      }
    
}