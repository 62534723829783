<div class="toast-item" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
    <div fxFlex="0 0 auto" class="icon"></div>
    <div fxFlex="1 1 auto" class="content">
        <div class="title" *ngIf="toast?.title">
            {{toast?.title}}
        </div>
        <div class="description" *ngIf="toast?.description">
            {{toast?.description}}
        </div>
    </div>
    <div fxFlex="0 0 auto" class="action">
        <button mat-icon-button color="primary" type="button" matTooltip="Acknowledge" (click)="onClose($event)">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </div>
</div>
