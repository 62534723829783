import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TCGroup } from './group.model';
import { Injectable } from '@angular/core';

export interface GroupsState extends EntityState<TCGroup> {}

@Injectable( { providedIn: 'root' } )
@StoreConfig({ name: 'groups' })
export class GroupsStore extends EntityStore<GroupsState> {

  constructor() {
    super();
  }

}

