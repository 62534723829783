import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
} from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TCDevice } from 'src/app/state';
import { ConfigService, ServiceLocator } from 'src/app/services';
import { Columns, Config, Event } from 'ngx-easy-table';
import { capitalizeFirstLetter } from 'src/app/utils';

@Component({
  selector: 'fod2-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss'],
})
export class DevicesListComponent implements OnInit {
  @ViewChild('actionsColumn', { static: true })
  private actionsColumnRef!: TemplateRef<any>;

  @ViewChild('categoryColumn', { static: true })
  private categoryColumnRef!: TemplateRef<any>;

  @ViewChild('timeColumn', { static: true })
  private timeColumnRef!: TemplateRef<any>;

  public locale: string;

  @Input()
  public configuration: Config = {
    ...ConfigService.config,
    selectRow: true,
  };

  @Input()
  public devices$: Observable<any> = EMPTY;

  @Input()
  public collapsedColumns: Columns[] = [];

  @Input()
  public expandedColumns: Columns[] = [];

  @Input()
  public expanded = false;

  private _showEdit = true;
  @Input()
  set showEdit(value: boolean) {
    this._showEdit = coerceBooleanProperty(value);
  }
  get showEdit(): boolean {
    return this._showEdit;
  }

  public _showDelete = true;
  @Input()
  set showDelete(value: boolean) {
    this._showDelete = coerceBooleanProperty(value);
  }
  get showDelete(): boolean {
    return this._showDelete;
  }

  @Output()
  public edit = new EventEmitter<TCDevice>();

  @Output()
  public delete = new EventEmitter<TCDevice>();

  @Output()
  public deviceSelected = new EventEmitter<TCDevice>();

  constructor() {
    this.locale = ServiceLocator.injector.get(LOCALE_ID);
  }

  ngOnInit() {
    this.collapsedColumns = [
      //{ key: 'id', title: 'Id', placeholder: 'Search', width: '100px' },
      //{ key: 'uniqueId', title: 'Identifier', placeholder: 'Search', width: '30%' },
      { key: 'name', title: 'Name', placeholder: 'Search' },
      {
        key: 'phone',
        title: 'SIM',
        placeholder: 'Search',
        width: '20%',
      },
      {
        key: 'category',
        title: 'Type',
        placeholder: 'Search',
        cellTemplate: this.categoryColumnRef,
        width: '15%',
      },
      {
        key: 'lastUpdate',
        title: 'Last update',
        searchEnabled: false,
        cellTemplate: this.timeColumnRef,
        width: '30%',
      },
      {
        key: 'id',
        title: '',
        searchEnabled: false,
        cellTemplate: this.actionsColumnRef,
        width: '80px',
      },
    ];

    this.expandedColumns = this.collapsedColumns;
  }

  public get columnsDef() {
    return this.collapsedColumns;
  }

  public onEdit(event: MouseEvent, row: TCDevice) {
    event.stopPropagation();

    if (this.showEdit) {
      this.edit.emit(row);
    }
  }

  public onDelete(event: MouseEvent, row: TCDevice) {
    event.stopPropagation();

    if (this.showDelete) {
      this.delete.emit(row);
    }
  }

  public onEvent($event: any) {
    if ($event.event === Event.onClick) {
      const device = $event.value.row;
      if (!!device) {
        this.deviceSelected.emit(device);
      }
    }
  }

  public capitalizeFirstLetter(s: string) {
    return capitalizeFirstLetter(s);
  }
}
