import { TayaracMaterialModule } from './../tayarac-material/tayarac-material.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastComponent } from './toast-component/toast.component';
import { ToastItemComponent } from './toast-item/toast-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, TayaracMaterialModule, FlexLayoutModule],
  declarations: [ToastComponent, ToastItemComponent],
  exports: [ToastComponent, ToastItemComponent],
})
export class ToastModule {

}
