import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Toast } from '../toast';

@Component({
  selector: 'labs-toast-item',
  templateUrl: './toast-item.component.html',
  styleUrls: ['toast-item.component.scss'],
})
export class ToastItemComponent {
  @Input()
  toast?: Toast;

  @Output()
  public toastClosed = new EventEmitter<Toast>()

  public onClose($event: MouseEvent) {
      this.toastClosed.emit( this.toast);
  }
}
