<ngx-table
  [id]="'visitsList'"
  [configuration]="configuration"
  [data]="rows$ | async"
  [columns]="columns"
  (event)="onEvent($event)"
  [filtersTemplate]="filtersTemplate"
></ngx-table>

<ng-template #actionsColumn let-row>
  <div
    class="action-cell"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="0px"
  >
    <button
      mat-icon-button
      color="primary"
      (click)="onVisitInfo($event, row)"
      type="button"
      matTooltip="Visit Info"
    >
      <mat-icon fontSet="fas" fontIcon="fa-eye" class="mat-24"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="onEndVisit($event, row)"
      type="button"
      [disabled]="!!row.endTime"
      matTooltip="End Visit"
    >
      <mat-icon
        fontSet="fas"
        fontIcon="fa-flag-checkered"
        class="mat-24"
      ></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #startColumn let-row>
  <div>{{ row.startTime | date: "medium":locale }}</div>
  <div>
    <i class="fas fa-caret-right till-arrow"></i>
    {{ row.endTime | date: "medium":locale }}
  </div>
</ng-template>

<ng-template #devNameColumn let-row>
  <div matTooltip="{{ row.device?.phone }}">{{ row.device?.name }}</div>
</ng-template>

<ng-template #nameColumn let-row>
  <div matTooltip="{{ row.visitor?.attributes?.phone }}">
    {{ row.visitor?.name }}
  </div>
</ng-template>

<ng-template #filtersTemplate>
  <th>
    <input
      class="form-input"
      (input)="onDeviceIdSearch($event.target)"
      type="text"
      id="filter-deviceId"
      placeholder="Device..."
      matTooltip="Filter by device"
    />
  </th>
  <th>
    <input
      class="form-input"
      (input)="onVisitorSearch($event.target)"
      type="text"
      id="filter-visitor"
      placeholder="Visitor name..."
      matTooltip="Filter by visitor"
    />
  </th>
  <th>
    <input
      class="calendar-input"
      [owlDateTime]="dt1"
      [owlDateTimeTrigger]="dt1"
      (dateTimeChange)="onDateSearch($event.value)"
      matTooltip="Filter by date"
    />
    <span class="trigger" [owlDateTimeTrigger]="dt1">
      <i class="fas fa-calendar-alt"></i>
    </span>
    <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
  </th>
  <th></th>
</ng-template>
