<div
  class="map-area"
  leaflet
  [leafletOptions]="options"
  [leafletLayersControl]="layersControl"
  [leafletLayers]="layers"
  [leafletFitBounds]="latLngBounds"
  [leafletCenter]="center"
  [leafletZoom]="zoom"
></div>
