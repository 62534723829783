// https://github.com/Asymmetrik/ngx-leaflet-draw

import { Component, OnInit, OnDestroy } from '@angular/core';
import { tileLayer, latLng, Layer, LatLngBounds, Control, LatLng } from 'leaflet';
import { WebSocketService, wsBaseURL, ServiceLocator, wsTimeout } from '../services';
import { CookieService } from 'ngx-cookie-service';
import { TracCarService } from '../trac-car';


@Component({
    selector: 'app-map-editor',
    templateUrl: './map-editor.component.html',
    styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit, OnDestroy {
    
    
    constructor(protected tcService: TracCarService, cookieSvc: CookieService) {
        this.wsService = new WebSocketService(cookieSvc);
        this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
        this.wsTimeout = ServiceLocator.injector.get(wsTimeout);
    }
    
    protected wsService: WebSocketService;
    protected wsBaseUrl: string;
    protected wsTimeout = 5000;
    
    public layersControl: any = {};
    
    protected drawControl?: Control.Draw;
    public showControl = false;
    
    public layers: Layer[] = [];
    public center = latLng(50.844571960481055, 4.391028285026551);
    public latLngBounds: LatLngBounds = new LatLngBounds([[50.847126, 4.387525], [50.847099, 4.398319], [50.842221, 4.398426], [50.842316, 4.387504]]);
    
    public options = {
        layers: [
            tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '<a href="http://seris.be">Seris Technology FOB2</a>' })
        ],
        zoom: 10,
        center: latLng(50.844571960481055, 4.391028285026551)
    };
    
    public drawOptions: Control.DrawConstructorOptions = {
        position: 'bottomleft',
        draw: {
            polygon: {
                allowIntersection: false,
                repeatMode: false,
                drawError: {
                    message: 'Intersections are not allowed'
                }
            },
            marker: false,
            polyline: false,
            rectangle: false,
            circlemarker: false,
            circle: false,
        }
    };
    
    ngOnInit() {
        
        this.layersControl = {
            baseLayers: {
                'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '<a href="http://seris.be">Seris Technology FOB2</a>' }),
                'Thunder Forest Map': tileLayer('https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=b1dda05e8ca647328224e0838408aef2', { maxZoom: 18, attribution: '<a href="http://seris.be">Seris Technology FOB2</a>' })
            },
            overlays: {
            }
        };
    }
    
    ngOnDestroy(): void {
    }
    
    public leafletDrawCreated($event: any) {
        const latLngs: LatLng[] = $event.layer.getLatLngs();
        const geoJSON = $event.layer.toGeoJSON();
        console.log('leafletDrawCreated: ', $event);
    }
    
    public leafletDrawEdited($event: any) {
        const latLngs: LatLng[] = $event.layers.getLayers()[0].getLatLngs();
        const geoJSON = $event.layers.getLayers()[0].toGeoJSON();
        console.log('leafletDrawEdited: ', $event);
    }
    
    public leafletDrawEditStart($event: any) {
        console.log('leafletDrawEditStart: ', $event);
    }
    
    public leafletDrawEditStop($event: any) {
        console.log('leafletDrawEditStop: ', $event);
        this.showControl = false;
    }
    
    public leafletDrawDeleted($event: any) {
        console.log('leafletDrawDeleted: ', $event);
    }
    
    public leafletDrawStart($event: any) {
        console.log('leafletDrawStart: ', $event);
    }
    
    public leafletDrawStop($event: any) {
        console.log('leafletDrawStop: ', $event);
        this.showControl = false;
    }
    
    public onDrawReady(drawControl: Control.Draw) {
        console.log('onDrawReady: ', drawControl);
        this.drawControl = drawControl;
    }
    
    public onAdd($event: any) {
        this.showControl = true;
    }
}
