import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TracCarService } from '../trac-car';
import { Fod2Service } from '../state';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(private tcSvc: TracCarService, private fod2Service: Fod2Service) { }

    login(username: string, password: string): Observable<any> {

        return this.tcSvc.startSession(username, password).pipe(
            map(user => {
                const authUser = {
                    ...user,
                    username: username,
                    password: password,
                    authdata: window.btoa(username + ':' + password)
                };

                return authUser;
            }),
            tap(user => {
                this.fod2Service.reset();
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.tcSvc.reconnect();
            })
        );
    }

    logout() {
        // remove user from local storage to log user out
        this.fod2Service.reset();
        this.tcSvc.endSession();
        localStorage.removeItem('currentUser');
    }
}
