<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" 
    [mode]="(isHandset$ | async) ? 'over' : 'side'"  [opened]="false && !(isHandset$ | async)">
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLinkActive="active" routerLink="/home">Home</a>
      <a mat-list-item routerLinkActive="active" routerLink="/map" *ngIf="false">Map</a>
      <a mat-list-item routerLinkActive="active" routerLink="/devices" *ngIf="false">Devices</a>
      <a mat-list-item routerLinkActive="active" routerLink="/test" *ngIf="false">Test</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="(isHandset$ | async)">
        <mat-icon aria-label="Side nav toggle icon"><i class="fas fa-bars"></i></mat-icon>
      </button>
      <span class="as-link" routerLink="/home">FOD2 Visitor Tracking</span>
      
      <button type="button" aria-label="Map board" mat-icon-button routerLink="/map">
        <mat-icon><i class="fas fa-map-marked-alt"></i></mat-icon>
      </button>
      <button type="button" aria-label="Reports" mat-icon-button routerLink="/reports">
        <mat-icon><i class="fa fa-chart-bar"></i></mat-icon>
      </button>      
      <button type="button" aria-label="Devices" mat-icon-button routerLink="/devices" *ngIf="false">
        <mat-icon><i class="fas fa-location-arrow"></i></mat-icon>
      </button>
      <button type="button" aria-label="Devices" mat-icon-button routerLink="/test" *ngIf="false">
        <mat-icon><i class="fas fa-wrench"></i></mat-icon>
      </button>
      <span  style="flex: 1"></span>
      <mat-spinner diameter="40" color="accent" *ngIf="(loading$ | async)"></mat-spinner>
      <span  style="flex: 1"></span>
      <a mat-list-item routerLinkActive="active" *ngIf="isLoggedIn()" (click)="logout()">Logout</a>
    </mat-toolbar>
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>