import { QueryEntity } from '@datorama/akita';
import { GeofencesStore, GeofencesState } from './geofences.store';
import { Injectable } from '@angular/core';

@Injectable( { providedIn: 'root' } )
export class GeofencesQuery extends QueryEntity<GeofencesState> {

  constructor(protected override store: GeofencesStore) {
    super(store);
  }

}
