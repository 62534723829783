import {TCDriver} from './../../state/drivers/driver.model';
import {TCVisitFull} from './../../state/visits/visit.model';
import {VisitInfoComponent} from './../visit-info/visit-info.component';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
  OnDestroy,
} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {
  VisitsQuery,
  DriversQuery,
  VisitsService,
  VisitFilter,
  DevicesQuery,
  TCDevice,
} from 'src/app/state';
import {ConfigService, ServiceLocator} from 'src/app/services';
import {Columns, Config, Event} from 'ngx-easy-table';
import {isNil} from '@datorama/akita';
import {map, debounceTime} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {GroupsQuery} from "../../state/groups";
import {TracCarService} from "../../trac-car";

export type VisitInfoType = TCVisitFull & { device: TCDevice, visitor: TCDriver };

@Component({
  selector: 'fod2-visits-list',
  templateUrl: './visits-list.component.html',
  styleUrls: ['./visits-list.component.scss'],
})
export class VisitsListComponent implements OnInit, OnDestroy {
  @ViewChild('actionsColumn', {static: true})
  private actionsColumnRef!: TemplateRef<any>;

  @ViewChild('startColumn', {static: true})
  private startColumnRef: TemplateRef<any> | undefined;

  @ViewChild('nameColumn', {static: true})
  private nameColumnRef: TemplateRef<any> | undefined;

  @ViewChild('devNameColumn', {static: true})
  private devNameColumnRef: TemplateRef<any> | undefined;


  @Input()
  public configuration: Config = {
    ...ConfigService.config,
    rows: 10,
    threeWaySort: false,
    selectRow: true,
    tableLayout: {
      ...ConfigService.config.tableLayout,
      borderless: false,
    },
  };

  @Input()
  public visits$: Observable<TCVisitFull[]> | undefined;

  @Input()
  public columns: Columns[] = [];

  @Output()
  public endVisit = new EventEmitter<TCVisitFull>();

  @Output()
  public visitSelected = new EventEmitter<TCVisitFull>();

  public locale: string;
  protected dialog: MatDialog;

  public rows$: Observable<any> | undefined;

  //	protected filter: {[index: string]:any} = {};
  protected filter: VisitFilter = {};
  protected filterSubj = new BehaviorSubject<VisitFilter>({});

  constructor(
    protected visitsSvc: VisitsService,
    private visitsQuery: VisitsQuery,
    private driversQuery: DriversQuery,
    private devicesQuery: DevicesQuery,
    private tracCarSvc: TracCarService
  ) {
    this.locale = ServiceLocator.injector.get(LOCALE_ID);
    this.dialog = ServiceLocator.injector.get(MatDialog);

    if (isNil(this.visits$)) {
      this.visits$ = this.visitsQuery.selectFiltered();
    }

    this.rows$ = this.visits$?.pipe(
      map((vs) => {
        const x = vs.map((v) => {
          const d = this.driversQuery.getEntity(v.driverId);
          const dev = this.devicesQuery.getEntity(v.deviceId);
          return {
            ...v,
            device: dev,
            visitor: d,
          };
        });

        return x;
      })
    );
  }

  ngOnInit() {
    this.columns = [
      // { key: 'id', title: 'Id', placeholder: 'Search', width: '20px' },
      // { key: 'device.name', title: 'Device', placeholder: 'Search', width: '15%' },
      // { key: 'visitor.name', title: 'Visitor', placeholder: 'Search', width: '30%' },
      {
        key: 'device',
        title: 'Device',
        searchEnabled: true,
        cellTemplate: this.devNameColumnRef,
        width: '20%',
      },
      {
        key: 'visitor',
        title: 'Visitor',
        searchEnabled: true,
        cellTemplate: this.nameColumnRef,
        width: '30%',
      },
      {
        key: 'startTime',
        title: 'Time',
        searchEnabled: false,
        cellTemplate: this.startColumnRef,
        orderBy: 'desc',
      },
      {
        key: 'id',
        title: '',
        searchEnabled: false,
        cellTemplate: this.actionsColumnRef,
        width: '80px',
      },
    ];

    this.filterSubj
      .asObservable()
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((f) => this.visitsSvc.updateFilter(f));
  }

  ngOnDestroy() {
  }

  public onEndVisit(event: MouseEvent, row: TCVisitFull) {
    event.stopPropagation();
    this.endVisit.emit(row);
  }

  public onVisitInfo(event: MouseEvent, visit: TCVisitFull) {
    event.stopPropagation();

    let dialogRef = this.dialog.open(VisitInfoComponent, {
      width: 'auto',
      minWidth: '500px',
      data: visit
    });


  }

  public onEvent($event: any) {
    if ($event.event === Event.onClick) {
      const visit = $event.value.row;
      if (!!visit) {
        this.visitSelected.emit(visit);
      }
    }
  }

  public onFilterSearch(
    what: keyof VisitFilter,
    value: moment.Moment & string
  ) {
    this.filter[what] = value;
    this.filterSubj.next(this.filter);
  }

  public onDateSearch(value: moment.Moment) {

    const v = moment(value);
    this.tracCarSvc.loadVisitsForDay(v.isValid() ? v : undefined);

    this.filter = {...this.filter, selectedDate: (v.isValid() ? v : undefined)};
    this.filterSubj.next(this.filter);
  }

  public onVisitorSearch(value: EventTarget | null) {
    this.filter = {
      ...this.filter,
      visitorName: (value as HTMLTextAreaElement)?.value,
    };
    this.filterSubj.next(this.filter);
  }

  public onDeviceIdSearch(value: EventTarget | null) {
    this.filter = {
      ...this.filter,
      deviceName: (value as HTMLTextAreaElement)?.value,
    };
    this.filterSubj.next(this.filter);
  }
}
