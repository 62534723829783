import { QueryEntity, isNil } from '@datorama/akita';
import { DevicesStore, DevicesState } from './devices.store';
import { Injectable } from '@angular/core';
import { VisitsQuery } from '../visits/visits.query';
import { TCDevice } from '.';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DevicesQuery extends QueryEntity<DevicesState> {

    constructor(protected override store: DevicesStore, protected visitQuery: VisitsQuery) {
        super(store);
    }

    public selectInVisit(): Observable<TCDevice[]> {
        return combineLatest([this.selectAll(), this.visitQuery.selectOngoing()]).pipe(
            map(dv => {
                const ds = dv[0].filter(d => !isNil(dv[1].find(v => v.deviceId === d.id)));
                return ds;
            })
        );
    }

    public getInVisit(): TCDevice[] {
        const vs = this.visitQuery.getOngoing();
        return this.getAll({ filterBy: d => !isNil(vs.find(v => v.deviceId === d.id)) });
    }

    public selectAvailable() {
        return combineLatest([this.selectAll(), this.visitQuery.selectOngoing()]).pipe(
            map(dv => {
                const ds = dv[0].filter(d => isNil(dv[1].find(v => v.deviceId === d.id)));
                return ds;
            })
        );
    }
}
