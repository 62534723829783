import { arrayDistinct, arrayEquals, isGeoFenceForbidden } from 'src/app/utils';
import { GeofencesQuery } from './../state/geofences/geofences.query';
import { DeviceAndVisit, Fod2Query } from './../state/fod2/fod2.query';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { combineLatestWith, map, scan, share, tap } from 'rxjs/operators';
import { EventsService, TCEvent, VisitsQuery } from 'src/app/state';
import { EventsQuery } from '../state';
import { capitalizeFirstLetter, splitOnCapitals } from '../utils';
import { Toast } from './toast';
import { getPointsOfGeoFence, isMarkerInsidePolygon } from '../map/map.utils';
import { LatLng } from 'leaflet';
import { environment } from 'src/environments/environment';

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  items$: Observable<Toast[]>;

  protected ackVisit$ = new BehaviorSubject<number[]>([]);

  public constructor(
    protected eventsQuery: EventsQuery,
    protected eventsSvc: EventsService,
    protected fod2Query: Fod2Query,
    protected geoQuery: GeofencesQuery
  ) {
    this.items$ = environment.toastsFromEvents
      ? this.toastsFromEvents()
      : this.toastsFromVisits();
  }

  public acknowledgeEvent(event: any) {
    if (!event) return;

    const id = environment.toastsFromEvents ? event.id : event.visit.id;
    const ackVs = this.ackVisit$.getValue().filter((i) => i !== id);

    let res = [...ackVs, id];
    this.ackVisit$.next(res.sort((a, b) => a - b));
  }

  protected toastsFromVisits(): Observable<Toast[]> {
    const visits$ = combineLatest([
      this.fod2Query.allVisits$, // allCurrentVisits$,
      this.geoQuery.selectAll(),
    ]).pipe(
      map(([devs, geoF]) => {
        const forbiddenGeoFences = geoF
          .filter((gf) => isGeoFenceForbidden(gf))
          .map((gf) => ({ gf: gf, bound: getPointsOfGeoFence(gf) }));

        const devsInForbidden = devs.map((dev) => {
          const pos = dev.position;

          const forbGF = pos
            ? forbiddenGeoFences.find((gb) =>
                isMarkerInsidePolygon(
                  new LatLng(pos.latitude, pos.longitude),
                  gb.bound
                )
              )
            : undefined;

          const isForbidden: boolean = !!forbGF;

          return {
            ...dev,
            geoFenceName: forbGF?.gf?.name,
            inForbiddenArea: isForbidden,
          };
        });

        return devsInForbidden;
      }),
      share()
    );

    visits$
      .pipe(
        map((devs) => {
          return devs
            .filter((dev) => dev.inForbiddenArea && dev.visit)
            .map((dev) => dev.visit!.id);
        }),
        combineLatestWith(this.ackVisit$),
        tap(([visId, ackIds]) => {
          // From those acknoledged leave only those with visit in forbidden area
          const newAcks = ackIds.filter((id) =>
            visId.some((vid) => vid === id)
          );

          if (!arrayEquals(newAcks, ackIds)) {
            this.ackVisit$.next(newAcks.sort((a, b) => a - b));
          }
        })
      )
      .subscribe();

    const toasts$ = visits$.pipe(
      map((devs) => {
        return devs.filter((dev) => dev.inForbiddenArea);
      }),
      map((devs) => {
        const toasts = devs.map((dev) => {
          const title =
            'Visitor: ' +
            (dev.visitor?.name || '<unknown visitor>') +
            (dev.geoFenceName ? `  zone ${dev.geoFenceName}` : '');
          const descr = 'Device: ' + (dev.device?.name || '<unknown device>');

          return new Toast(title, descr, dev);
        });
        return toasts;
      }),
      combineLatestWith(this.ackVisit$.asObservable()),
      map(([devs, acks]) => {
        return devs.filter(
          (dev) => !acks.some((a) => a === dev.data.visit?.id)
        );
      }),

      scan((ac, toasts) => {
        const old = ac.filter((o) =>
          toasts.some((n) => o.data.visit.id === n.data.visit.id)
        );
        const newt = toasts.filter(
          (n) => !ac.some((o) => o.data.visit.id === n.data.visit.id)
        );
        return [...old, ...newt];
      })
    );

    return toasts$;
  }

  protected toastsFromEvents(): Observable<Toast[]> {
    const events$ = this.eventsQuery
      .selectAll({ filterBy: (e) => e.type === 'alarm' })
      .pipe(
        map((evs) => {
          const toasts = evs.map((ev) => {
            const time = moment(ev.eventTime).format('DD MMM yyyy, HH:mm:ss');
            const title = splitOnCapitals(
              capitalizeFirstLetter(ev.attributes?.alarm)
            );
            return new Toast(title, time, ev);
          });
          return toasts;
        }),
        combineLatestWith(this.ackVisit$.asObservable()),
        map(([devs, acks]) => {
          return devs.filter((dev) => !acks.some((a) => a === dev.data.id));
        })
      );

    return events$.pipe(
      scan((ac, newEvs) => {
        const old = ac.filter((t) =>
          newEvs.some((ev) => t.data.id === ev.data.id)
        );
        const newe = newEvs.filter(
          (ev) => !ac.some((t) => t.data.id === ev.data.id)
        );
        return [...old, ...newe];
      })
    );
  }
}
