export interface TCGeofence {
    id: number;
    name: string;
    description: string;
    area: string;
    calendarId: number;
    attributes: any;
}

export function createGeofence(params: Partial<TCGeofence>) {
    return {
        ...params
    } as TCGeofence;
}
