import { FeatureGroup, Icon, LatLng, Marker, marker } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import * as svg from './svgs';

export type EventColor = 'red' | 'blue';

export interface EventMarkerOptions {
  eventId: number;
  position?: LatLng;
  time: moment.Moment;
  type: string;
  deviceId: number;
  expires: moment.Moment;
  color: EventColor;
}

export class EventDraw {
  private events$ = new BehaviorSubject<EventMarkerOptions[]>([]);

  constructor(private eventMarkers: FeatureGroup) {}

  public createEventMarkers(options: EventMarkerOptions[]) {
    this.eventMarkers.clearLayers();
    options
      .filter((o) => !!o.position)
      .map((o) => {
        const pos = o.position!;
        const time = o.time;
        const icon = o.color === 'blue' ? svg.blueHairCross : svg.redHairCross;

        const layer = marker([pos.lat, pos.lng], {
          icon: icon,
          title: `${time.format('DD:MM:yy')} ${time.format('HH:mm:ss')}`,
          riseOnHover: true,
        });
        return layer;
      })
      .forEach((m) => this.eventMarkers.addLayer(m));
  }

  public clearMarkers() {
    if (this.eventMarkers.getLayers().length > 0) {
      this.eventMarkers.clearLayers();
    }
  }
}
