import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TCDriver } from './driver.model';
import { Injectable } from '@angular/core';

export interface DriversState extends EntityState<TCDriver> { }

@Injectable( { providedIn: 'root' } )
@StoreConfig( { name: 'drivers' } )
export class DriversStore extends EntityStore<DriversState> {

    constructor() {
        super();
    }

}

