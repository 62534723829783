import { InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceLocator } from '../services';
import { TCTrip } from './dto/tc-trip';
import { TCGeofence } from '../state/geofences';
import { addHttpParam } from '../utils';

export let tcBaseURL = new InjectionToken<string>('tcBaseURL');

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export abstract class TracCarAbstractRestService<
  Type extends Partial<{ id: number }>
> {
  protected httpClient: HttpClient;
  protected _baseUrl: string;

  constructor() {
    this.httpClient = ServiceLocator.injector.get(HttpClient);
    this._baseUrl = ServiceLocator.injector.get(tcBaseURL);
  }

  protected abstract get url(): string;

  public create(item: Type, options?: HttpOptions): Observable<Type> {
    const url = `${this._baseUrl}${this.url}`;
    return this.httpClient.post<Type>(url, item, options);
  }

  public createPermission(
    item: { [key: string]: number | null },
    options?: HttpOptions
  ): Observable<Type> {
    const url = `${this._baseUrl}/permissions`;
    return this.httpClient.post<Type>(url, item, options);
  }

  public read(options?: HttpOptions): Observable<Type[]> {
    const url = `${this._baseUrl}${this.url}`;
    return this.httpClient.get<Type[]>(url, options);
  }

  public update(item: Type, options?: HttpOptions): Observable<Type> {
    const url = `${this._baseUrl}${this.url}/${item.id}`;
    return this.httpClient.put<Type>(url, item, options);
  }

  public erase(itemId: number, options?: HttpOptions): Observable<object> {
    const url = `${this._baseUrl}${this.url}/${itemId}`;
    return this.httpClient.delete<Type>(url, options);
  }

  public getTrips(
    deviceId: number | number[],
    groupId: number | number[],
    from: string,
    to: string
  ): Observable<TCTrip[]> {
    let params = new HttpParams();
    if (deviceId) {
      if (Array.isArray(deviceId)) {
        deviceId.forEach((devId) => {
          params = params.append('deviceId', `${devId}`);
        });
      } else {
        params = params.append('deviceId', `${deviceId}`);
      }
    }
    if (groupId) {
      if (Array.isArray(groupId)) {
        groupId.forEach((grId) => {
          params = params.append('groupId', `${grId}`);
        });
      } else {
        params = params.append('groupId', `${groupId}`);
      }
    }
    if (from) {
      params = params.append('from', from);
    }
    if (to) {
      params = params.append('to', to);
    }

    return this.httpClient.get<TCTrip[]>(this._baseUrl + '/reports/trips', {
      params: params,
    });
  }
}
