import { Icon, LatLng, latLng, LayerGroup, marker, polyline } from 'leaflet';
import * as moment from 'moment';
import { TCPosition, TCVisitFull } from '../state';
import VectorIcon from '../utils/vector-icon';
// import { VisitAndRoute } from './map.component';

export class VisitDraw {
  constructor(private visitPath: LayerGroup) {}

  public showVisit(visit?: TCVisitFull) {
    const pos = visit?.route || [];
    const deviceId = visit?.deviceId;

    this.visitPath.clearLayers();

    this.refreshPoly(this.visitPath, pos);

    if (pos.length > 0 && visit?.endTime) {
      const lastPos: TCPosition = pos[pos.length - 1];
      const endMarker = marker([lastPos.latitude, lastPos.longitude], {
        icon: new VectorIcon({
          icon: 'flag-checkered',
          markerColor: 'gray',
          extraClasses: 'fas',
          prefix: 'fa',
          iconUrl: '',
        }),

        title: moment(lastPos.fixTime).format('HH:mm:ss'),
        riseOnHover: true,
      });
      this.visitPath.addLayer(endMarker);
    }

    if (pos.length > 1) {
      const lastPos: TCPosition = pos[0];
      const startMarker = marker([lastPos.latitude, lastPos.longitude], {
        icon: new VectorIcon({
          icon: 'play',
          markerColor: 'gray',
          extraClasses: 'fas',
          prefix: 'fa',
          iconUrl: '',
        }),

        title: moment(lastPos.fixTime).format('HH:mm:ss'),
        riseOnHover: true,
      });
      this.visitPath.addLayer(startMarker);
    }
  }

  protected refreshPoly(lg: LayerGroup, pos: TCPosition[]) {
    const route = pos.map((p) => {
      return latLng(p.latitude, p.longitude);
    });

    const l = polyline(route);

    lg.addLayer(l);

    pos
      .map((p) => {
        const arrowMarker = new Icon({
          iconUrl: this.makeIcon(p.course),
          iconSize: [26, 26],
          shadowSize: [26, 26],
          iconAnchor: [13, 13],
          shadowAnchor: [0, 0],
          popupAnchor: [0, 0],
        });

        var x = moment(p.fixTime);
        var dateStr: string = '';

        try {
          dateStr = x.format('DD.MM.yy HH:mm:ss');
        } catch (e) {
//          console.error(e);
        }

        const layer = marker([p.latitude, p.longitude], {
          icon: arrowMarker,
          title: dateStr
        });
        return layer;
      })
      .forEach((ll) => lg.addLayer(ll));
  }

  protected makeIcon(rot: number): string {
    const arrowSvg =
      "<?xml version='1.0' encoding='UTF-8' standalone='no'?>" +
      "<svg id='svg3369' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns#' xmlns='http://www.w3.org/2000/svg'" +
      " height='26' viewBox='0 0 26 26' width='26' version='1.1'>" +
      "<polygon id='arrow' stroke='#0000ff' points='19 22 13 19 7 22 13 4' stroke-width='1px' fill='#00FF00ff' " +
      `transform='rotate(${rot} 13 13)'/>` +
      '</svg>';
    const url = 'data:image/svg+xml;base64,' + btoa(arrowSvg);
    return url;
  }
}
