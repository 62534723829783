import { TCPosition } from './../state/positions/position.model';
import { LatLng, latLng, LatLngBounds } from 'leaflet';
import { TCGeofence } from '../state';

export function getPointsOfGeoFence(gf: TCGeofence): LatLng[] {
  const area = gf.area;
  if (!area.startsWith('POLYGON')) return [];

  const pts: LatLng[] = convertPoints(
    area.substring(area.indexOf('((') + 2, area.indexOf('))'))
  );

  return pts;
}

export function convertPoints(coords: string): LatLng[] {
  const res = coords.split(',').map((duo) => {
    const duoPt = duo.trim().split(' ');
    const lat = +duoPt[0];
    const lng = +duoPt[1];
    return latLng(lat, lng);
  });
  return res;
}

export function isMarkerInsidePolygon(marker: LatLng, polyPoints: LatLng[]) {
  var x = marker.lat,
    y = marker.lng;

  var inside = false;
  for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    var xi = polyPoints[i].lat,
      yi = polyPoints[i].lng;
    var xj = polyPoints[j].lat,
      yj = polyPoints[j].lng;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function getBoundsOfGeoFence(gf: TCGeofence): LatLngBounds {
  const pts = getPointsOfGeoFence(gf);
  const bounds = new LatLngBounds(pts.map((x) => [x.lat, x.lng]));
  return bounds;
}

export function angleFromCoordinate(
  la1: number,
  ln1: number,
  la2: number,
  ln2: number
): number {
  const lat1 = (la1 * Math.PI) / 180.0;
  const lng1 = (ln1 * Math.PI) / 180.0;
  const lat2 = (la2 * Math.PI) / 180.0;
  const lng2 = (ln2 * Math.PI) / 180.0;

  const dLon = lng2 - lng1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  let brng = Math.atan2(y, x);

  brng = ((180.0 * brng) / Math.PI + 360) % 360;
  // brng = 360 - brng; // count degrees counter-clockwise - remove to make clockwise
  return brng;
}

