<div>
    <div class="map-area" leaflet  [leafletOptions]="options"  
    [leafletLayersControl]="layersControl" [leafletLayers]="layers" [(leafletCenter)]="center"
    [leafletFitBounds]="latLngBounds">
    <div *ngIf="showControl" leafletDraw [leafletDrawOptions]="drawOptions" 
    (leafletDrawCreated) ="leafletDrawCreated($event)"
    (leafletDrawEditStart)="leafletDrawEditStart($event)"
    (leafletDrawEditStop)="leafletDrawEditStop($event)"
    (leafletDrawEdited)="leafletDrawEdited($event)"
    (leafletDrawDeleted)="leafletDrawDeleted($event)"
    (leafletDrawStart)="leafletDrawStart($event)"
    (leafletDrawStop)="leafletDrawStop($event)"
    (leafletDrawReady)="onDrawReady($event)"
    >
    </div>
</div>

<button type="button" (click)="onAdd($event)">Add Zone</button>