import { GeofencesQuery } from './../../state/geofences/geofences.query';
import { ALARM_TYPES } from './../../utils/utils';
import { onlyAlarmTypeEvents } from 'src/app/utils';
import { TCEvent } from './../../state/events/event.model';
import {
  Component,
  OnInit,
  OnDestroy,
  LOCALE_ID,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ConfigService, ServiceLocator } from 'src/app/services';
import {
  Fod2Service,
  TCVisitFull,
  VisitsQuery,
  VisitsService,
  TCDriver,
  TCDevice,
  DriversQuery,
  DevicesQuery,
} from 'src/app/state';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable, of as observableOf } from 'rxjs';
import { Columns, Config } from 'ngx-easy-table';

interface VisitInfo {
  visit: TCVisitFull;
  driver?: TCDriver;
  device?: TCDevice;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit, OnDestroy {
  @ViewChild('startTimeColumn', { static: true })
  private startTimeColumnRef!: TemplateRef<any>;

  @ViewChild('endTimeColumn', { static: true })
  private endTimeColumnRef!: TemplateRef<any>;

  @ViewChild('alarmsColumn', { static: true })
  private alarmsColumnRef!: TemplateRef<any>;

  @ViewChild('eventTimeColumn', { static: true })
  private eventTimeColumnRef!: TemplateRef<any>;

  public reportDate: Date;
  public rows$: Observable<any> = observableOf([]);

  public nestedRows$: Observable<any> = observableOf([]);

  public locale: string;

  public toggled = true;

  public configuration: Config = {
    ...ConfigService.config,
    searchEnabled: false,
    threeWaySort: false,
    paginationEnabled: false,
    rows: Number.MAX_SAFE_INTEGER,
    selectRow: false,
    collapseAllRows: this.toggled,
    tableLayout: {
      ...ConfigService.config.tableLayout,
      borderless: false,
    },
  };

  public nestedConfiguration: Config = {
    ...ConfigService.config,
    threeWaySort: false,
    paginationEnabled: false,
    rows: Number.MAX_SAFE_INTEGER,
    selectRow: false,
    collapseAllRows: this.toggled,
    tableLayout: {
      ...ConfigService.config.tableLayout,
      borderless: false,
    },
  };

  public columns: Columns[] = [];
  public nestedColumns: Columns[] = [];

  constructor(
    private visitsQuery: VisitsQuery,
    private visitsSvc: VisitsService,
    private fod2Svc: Fod2Service,
    private driverQuery: DriversQuery,
    private deviceQuery: DevicesQuery,
    private geoFencQuery: GeofencesQuery
  ) {
    this.locale = ServiceLocator.injector.get(LOCALE_ID);
    this.reportDate = new Date();
    this.reportDate.setHours(0, 0, 0, 0);

    this.visitsSvc.updateFilter({ selectedDate: moment(this.reportDate) });

    const visits$ = this.visitsQuery.selectFiltered().pipe(
      map((vs) => {
        const newVs = vs.map((v) => {
          return {
            visit: v,
            driver: this.driverQuery.getEntity(v.driverId),
            device: this.deviceQuery.getEntity(v.deviceId),
            events: onlyAlarmTypeEvents(v.events, this.geoFencQuery.getAll()),
          };
        });
        return newVs;
      })
    );

    this.rows$ = this.fod2Svc.load().pipe(
      mergeMap((_) => {
        return visits$;
      }),
      tap((vs) => {
        console.log(`Visits: ${vs}`);
      })
    );
  }

  ngOnInit(): void {
    this.columns = [
      {
        key: 'visit.id',
        title: 'Visit Id',
        searchEnabled: false,
        width: '10%',
      },
      {
        key: 'visit.startTime',
        title: 'Start Time',
        searchEnabled: false,
        cellTemplate: this.startTimeColumnRef,
        //width: '20%',
        orderBy: 'asc',
      },
      {
        key: 'visit.endTime',
        title: 'End Time',
        searchEnabled: false,
        cellTemplate: this.endTimeColumnRef,
        // width: '20%',
      },
      { key: 'driver.name', title: 'Visitor', searchEnabled: false },
      { key: 'device.name', title: 'Tracker', searchEnabled: false },
      { key: 'device.category', title: 'Tracker type', searchEnabled: false },
      {
        key: 'events',
        title: 'Alarms',
        searchEnabled: false,
        cellTemplate: this.alarmsColumnRef,
        width: '5%',
      },
    ];

    this.nestedColumns = [
      {
        key: 'eventTime',
        title: 'Event Time',
        searchEnabled: false,
        cellTemplate: this.eventTimeColumnRef,
        // width: '20%',
      },
      { key: 'type', title: 'Type', searchEnabled: false },
    ];
  }

  ngOnDestroy(): void {}

  toggleRows(): void {
    this.toggled = !this.toggled;
    this.configuration.collapseAllRows = this.toggled;
    this.configuration = { ...this.configuration };
  }

  public onDateSearch(date: any) {
    this.reportDate = moment(date).toDate();
    this.reportDate.setHours(0, 0, 0, 0);

    this.visitsSvc.updateFilter({ selectedDate: moment(this.reportDate) });
  }
}

function eventInVisit(ev: TCEvent, v: TCVisitFull) {
  if (ev.deviceId != v.deviceId) return false;

  const eventTime = moment(ev.eventTime);
  const start = moment(v.startTime);
  const end = moment(v.endTime);

  if (start.isValid() && eventTime < start) return false;

  if (end.isValid() && eventTime > end) return false;

  return true;
}
