import { Component, OnInit, Input, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';

@Component( {
    selector: 'fod2-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
} )
export class ConfirmationDialog implements OnInit {

    @Input()
    public title: string;

    @Input()
    public message: string;

    constructor( public dialogRef: MatDialogRef<ConfirmationDialog>, @Inject( MAT_DIALOG_DATA ) public data: any ) {

        const input = data || {};
        this.title = input.title || 'Confirmation';
        this.message = input.message || 'Are you sure?';
    }

    ngOnInit() {
    }

}
