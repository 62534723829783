<h1 mat-dialog-title>Visit info</h1>
<mat-divider></mat-divider>

<div mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <!-- Item specifics start here -->

  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
      <fieldset fxFlex="0 0 50%">
        <legend>Visit:</legend>
        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start">
          <div class="visit-start visit-field dialog-field">
            <label>Start:</label>
            <span class="field-data">{{
              visit.startTime | date: "medium":locale
            }}</span>
          </div>
          <div class="visit-end visit-field dialog-field">
            <label>End:</label>
            <span class="field-data">{{
              visit.endTime | date: "medium":locale
            }}</span>
          </div>
        </div>
      </fieldset>

      <fieldset fxFlex="0 0 50%">
        <legend>Device:</legend>
        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start">
          <div class="device-name device-field dialog-field">
            <label>Name:</label>
            <span class="field-data">{{ visit.device?.name }}</span>
          </div>
          <div class="device-phone device-field dialog-field">
            <label>Phone:</label>
            <span class="field-data">{{ visit.device?.phone }}</span>
          </div>
        </div>
      </fieldset>
    </div>
    <fieldset>
      <legend fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        Visitor:
        <a
          color="primary"
          class="legend-action"
          (click)="onToggleEdit()"
          matTooltip="Edir Visitor"
        >
          <mat-icon fontSet="fas" fontIcon="fa-cog" class="mat-16"></mat-icon>
        </a>
      </legend>
      <div
        fxLayout="column"
        fxLayoutGap="5px"
        fxLayoutAlign="start"
        *ngIf="!edit; else driverForm"
      >
        <div class="visitor-name visitor-field dialog-field">
          <label>Name:</label>
          <span class="field-data">{{ visit.visitor?.name }}</span>
        </div>
        <div class="visitor-phone visitor-field dialog-field">
          <label>Phone:</label>
          <span class="field-data">{{ visit.visitor?.attributes?.phone }}</span>
        </div>
        <div class="visitor-email visitor-field dialog-field">
          <label>Email:</label>
          <span class="field-data">{{ visit.visitor?.attributes?.email }}</span>
        </div>
        <div class="visitor-company visitor-field dialog-field">
          <label>Company:</label>
          <span class="field-data">{{ visit.visitor?.attributes?.company }}</span>
        </div>
      </div>
    </fieldset>
  </div>
  <!-- Item specifics end here -->
</div>

<mat-divider></mat-divider>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="20px" class="form-action">
  <button
    mat-raised-button
    color="primary"
    (click)="onCancel()"
    type="button"
    *ngIf="!edit"
  >
    Close
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onSave()"
    type="button"
    [disabled]="!isValid"
    *ngIf="edit"
  >
    Save
  </button>
  <button mat-raised-button (click)="onCancel()" type="button" *ngIf="edit">
    Cancel
  </button>
</div>

<ng-template #driverForm>
  <div fxLayout="column" fxLayoutAlign="start" [formGroup]="form">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="Name"
          type="text"
          id="name"
          name="name"
          formControlName="name"
        />
      </mat-form-field>
    </div>
    <input type="hidden" id="id" name="id" formControlName="id" />

    <div
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="start"
      formGroupName="attributes"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="First name"
          type="text"
          id="firstName"
          name="firstName"
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="Last name"
          type="text"
          id="lastName"
          name="lastName"
          formControlName="lastName"
        />
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="start"
      formGroupName="attributes"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="Email"
          type="email"
          id="email"
          name="email"
          formControlName="email"
        />
      </mat-form-field>
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="Phone"
          type="text"
          id="phone"
          name="phone"
          formControlName="phone"
        />
      </mat-form-field>
      <mat-form-field fxFlex>
        <input
          matInput
          placeholder="Company"
          type="text"
          id="company"
          name="company"
          formControlName="company"
        />
      </mat-form-field>
    </div>
  </div>
</ng-template>
