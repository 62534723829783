import { FormGroup, FormBuilder } from '@angular/forms';
import { Input, EventEmitter, Output, OnInit, OnDestroy, OnChanges, Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceLocator } from '../services';
import { clone } from '../utils';
import { isNil } from '@datorama/akita';

@Directive()
export abstract class AbstractFormComponent<F, T> implements OnInit, OnDestroy, OnChanges {

    public form: FormGroup;
    protected fb: FormBuilder;
    public selectedItem: T;

    public debug: any = null;

    @Input()
    public errorMsgs: string[] = [];

    @Output()
    protected saved = new EventEmitter<T>();

    @Output()
    protected canceled = new EventEmitter<T>();

    constructor( public dialogRef: MatDialogRef<F>, item: T ) {
        this.fb = ServiceLocator.injector.get( FormBuilder );

        this.selectedItem = clone( item );
        this.form = this.initForm( this.fb );
    }

    ngOnInit() {
        if ( !isNil( this.selectedItem ) ) {
            this.form.patchValue( this.selectedItem );
        }
    }

    ngOnDestroy() { }

    ngOnChanges() {
        if ( !isNil( this.selectedItem ) ) {
            this.form.patchValue( this.selectedItem );
        }
    }

    protected abstract initForm( fb: FormBuilder ): FormGroup;

    get isValid() {
        return this.form.valid;
    }

    public save( item: T ) {
        console.log( 'Form value: ', this.form.value );
        const x = Object.assign( {} as T, item, this.form.value );


        if ( !!this.dialogRef ) {
            this.dialogRef.close( x );
        } else {
            this.saved.emit( x );
        }
    }


    public cancel( p: T ) {
        if ( !!this.dialogRef ) {
            this.dialogRef.close();
        } else {
            this.canceled.emit( p );
        }
    }
}
