import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TCDevice, DevicesQuery } from '../state';

@Component( {
    selector: 'app-device-select',
    templateUrl: './device-select.component.html',
    styleUrls: ['./device-select.component.scss']
} )
export class DeviceSelectComponent implements OnInit, OnDestroy {

    public devices = new FormControl();
    public deviceFilterCtrl = new FormControl();

    protected filteredDevices: Observable<TCDevice[]>;

    @Output()
    public selected = new EventEmitter<TCDevice[]>();

    constructor(protected devQuery: DevicesQuery) {
        const filter$ = this.deviceFilterCtrl.valueChanges.pipe(
            startWith( '' ),
            map(f => f.toLowerCase())
        );
        this.filteredDevices = combineLatest( [filter$, this.devQuery.selectAll()] ).pipe(
            map( fd => {
                const f = fd[0];
                const devs = fd[1];

                if ( !f || f === '' ) {
                    return devs;
                }

                const fDevs = devs.filter( d => d.name.toLowerCase().indexOf( f ) > -1 );
                return fDevs;
            } )
        );

        this.devices.valueChanges.pipe(
                untilDestroyed(this)
                ).subscribe( ds => {
                    const devs = Array.isArray(ds) ? ds : [ds];
                    this.selected.emit(devs);
                });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    public get devices$(): Observable<TCDevice[]> {
        return this.filteredDevices;
    }
}
