import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { DriversService } from './../../state/drivers/drivers.service';
import { TCVisitFull } from './../../state/visits/visit.model';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractFormComponent } from 'src/app/components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clone, sanitizeError } from 'src/app/utils';
import { VisitInfoType } from '../visits-list/visits-list.component';
import { ServiceLocator } from 'src/app/services';
import { DevicesQuery, TCDevice, TCDriver } from 'src/app/state';
import { Observable } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { isNil } from '@datorama/akita';

@Component({
  templateUrl: './visit-info.component.html',
  styleUrls: ['./visit-info.component.scss'],
})
export class VisitInfoComponent implements OnInit, OnDestroy {
  public locale: string;

  public form: FormGroup;
  protected snackBar: MatSnackBar;

  public edit: boolean = false;
  protected selectedItem: TCDriver;

  constructor(
    public dialogRef: MatDialogRef<VisitInfoComponent>,
    private fb: FormBuilder,
    private driversSvc: DriversService,
    @Inject(MAT_DIALOG_DATA) public visit: VisitInfoType
  ) {
    this.locale = ServiceLocator.injector.get(LOCALE_ID);
    this.snackBar = ServiceLocator.injector.get(MatSnackBar);
    this.form = this.initForm(fb, visit.visitor);

    this.selectedItem = clone(visit.visitor);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  protected initForm(fb: FormBuilder, driver: TCDriver): FormGroup {
    const f = fb.group({
      id: driver?.id,
      name: [driver.name, [Validators.required]],
      attributes: fb.group({
        firstName: driver?.attributes?.firstName,
        lastName: driver?.attributes?.lastName,
        email: driver?.attributes?.email,
        phone: driver?.attributes?.phone,
        company: driver?.attributes?.company,
      }),
    });

    return f;
  }

  public onCancel() {
    this.dialogRef.close();
  }

  get isValid() {
    return this.form.valid;
  }

  public onSave() {
    const person = {
      ...this.selectedItem,
      ...this.form.value,
      attributes: {
        ...this.selectedItem?.attributes,
        ...this.form.value.attributes,
      },
    };

    console.log('Update visitor: ', person);
    const obs$ = this.driversSvc
      .save(person, undefined)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (visit: any) => {
          console.log(`The visitor ${person?.name} was updated`);
          this.dialogRef.close();
        },
        error: (e: any) => {
          if (!isNil(e)) {
            this.snackBar.open(sanitizeError(e), undefined, {
              duration: 5000,
              panelClass: 'snackbar-error',
            });
          }
        },
      });
  }

  public onToggleEdit() {
    this.edit = !this.edit;
  }
}
