
export interface TCDevice {
    id: number;
    name: string;
    uniqueId: string;
    status: string;
    disabled: boolean;
    lastUpdate: any; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
    positionId: number;
    groupId: number;
    phone: string;
    model: string;
    contact: string;
    category: string;
    geofenceIds: number[];
    attributes: any;
}


export function createDevice( params: Partial<TCDevice> ) {
    return {

    } as TCDevice;
}
