import { DeviceAndVisit } from './../state/fod2/fod2.query';
import { latLng, LatLng, LayerGroup, marker, Marker } from 'leaflet';
import {
  TCDevice,
  TCDriver,
  TCGeofence,
  TCPosition,
  TCVisitFull,
} from '../state';
import VectorIcon from '../utils/vector-icon';
import {
  angleFromCoordinate,
  getPointsOfGeoFence,
  isMarkerInsidePolygon,
} from './map.utils';
import { isGeoFenceForbidden, isGeoFencePermitted } from '../utils';

export class DeviceDraw {
  constructor(private deviceMarkers: LayerGroup) {}

  public clearDevices() {
    this.deviceMarkers.clearLayers();
  }

  public showDevices(devices: DeviceAndVisit[], fences: TCGeofence[]) {
    const markers = this.createMarkers(devices, fences);
    this.deviceMarkers.clearLayers();
    markers.forEach((d) => this.deviceMarkers.addLayer(d!));
  }

  public createMarkers(
    devices: DeviceAndVisit[],
    geoF: TCGeofence[]
  ): Marker[] {
    const forbiddenGfBound = geoF
      .filter((gf) => isGeoFenceForbidden(gf))
      .map((gf) => getPointsOfGeoFence(gf));
    const permittedGfBound = geoF
      .filter((gf) => isGeoFencePermitted(gf))
      .map((gf) => getPointsOfGeoFence(gf));

    const layers = devices
      .filter((device) => !!device.position)
      .map((device) => {
        const goingHome = this.isGoingHome(device.visit?.route);

        const layer = this.createDeviceMarker(
          device,
          forbiddenGfBound,
          permittedGfBound,
          false
        );
        return layer;
      })
      .filter((device) => device != null)
      .map((d) => d!);

    return layers;
  }

  public createDeviceMarker(
    devVisit: DeviceAndVisit,
    forbiddenGfBound: LatLng[][],
    permittedGfBound: LatLng[][],
    goingHome: boolean
  ): Marker<any> {
    const online = this.isOnline(devVisit.device) || true;
    const pos = devVisit.position!;

    const isForbidden: boolean = forbiddenGfBound.some((gb) =>
      isMarkerInsidePolygon(new LatLng(pos.latitude, pos.longitude), gb)
    );
    const isPermitted: boolean =
      !isForbidden &&
      permittedGfBound.some((gb) =>
        isMarkerInsidePolygon(new LatLng(pos.latitude, pos.longitude), gb)
      );

    const color =
      isForbidden || !online
        ? 'red'
        : goingHome
        ? 'orange'
        : isPermitted
        ? 'green'
        : !!devVisit.visit
        ? 'blue'
        : 'gray';

    const visitor = devVisit.visitor;
    const visitorPhone = visitor?.attributes?.phone;

    const title =
      `${devVisit.device.id}: ${devVisit.device.name}` +
      (devVisit.device.phone ? `\ndev: ${devVisit.device.phone}` : '') +
      (visitorPhone
        ? `\n\nVisitor: ${visitor?.name}\ntel: ${visitorPhone}`
        : '');

    let icon: string;
    switch (devVisit.device.category) {
      case 'car':
        icon = 'car';
        break;
      case 'person':
        icon = 'walking';
        break;
      default:
        icon = 'compass';
        break;
    }

    const layer = marker([pos.latitude, pos.longitude], {
      icon: new VectorIcon({
        icon: icon,
        markerColor: color,
        extraClasses: 'fas',
        prefix: 'fa',
        iconUrl: '',
      }),

      title: title,
      riseOnHover: true,
    });
    return layer;
  }

  protected isOnline(device: TCDevice): boolean {
    if (!device) {
      return false;
    }

    if (device.status !== 'online') {
      // console.log('Device gone offline: ', device);
      return false;
    }

    const threeMinAgo = new Date(
      new Date().setMinutes(new Date().getMinutes() - 3)
    );
    const lastUpdate = new Date(device.lastUpdate);
    const online = lastUpdate < threeMinAgo ? false : true;
    return online;
  }

  protected isGoingHome(route?: TCPosition[]): boolean {
    const pos = (route || []).slice(-3);

    if (pos.length < 3) return false;

    const user: any = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (!user?.longitude || !user?.latitude) return false;

    for (let i = 0; i < 2; i++) {
      const direction = angleFromCoordinate(
        pos[i].latitude,
        pos[i].longitude,
        pos[i + 1].latitude,
        pos[i + 1].longitude
      );

      const directionHome = angleFromCoordinate(
        pos[i].latitude,
        pos[i].longitude,
        user.latitude,
        user.longitude
      );

      if (Math.abs(direction - directionHome) > 10) return false;
    }

    return true;
  }
}
