import { EventsStore, EventFilter } from './events.store';
import { Injectable } from '@angular/core';
import { TCEvent } from './event.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AbstractReportRestService } from 'src/app/trac-car/abstract.report.rest.service';
import * as moment from 'moment';
import { DevicesQuery } from '../devices/devices.query';
import { arrayDistinct } from 'src/app/utils';

@Injectable({ providedIn: 'root' })
class EventsRestService extends AbstractReportRestService<TCEvent> {
  constructor() {
    super();
  }

  /**
   * Fetch a list of Events within the time period for the Devices or Groups
   * At least one deviceId or one groupId must be passed
   *
   * @param deviceId - device IDs to fetch events for. Only events related to these devices
   * @param groupId - group IDs to fetch events for. Only events related to devices in these groups
   * @param type  - % can be used to return events of all types
   * @param from  - in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to    - in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   */
  public getItems(
    deviceId?: number[],
    groupId?: number[],
    type?: string[],
    from?: string,
    to?: string
  ): Observable<TCEvent[]> {
    let params = new HttpParams();
    if (deviceId) {
      deviceId.forEach((devId) => {
        params = params.append('deviceId', `${devId}`);
      });
    }

    if (groupId) {
      groupId.forEach((grId) => {
        params = params.append('groupId', `${grId}`);
      });
    }

    if (type) {
      type.forEach((t) => {
        params = params.append('type', t);
      });
    }

    if (from) {
      params = params.append('from', from);
    }
    if (to) {
      params = params.append('to', to);
    }

    return this.httpClient.get<TCEvent[]>(this._baseUrl + '/reports/events', {
      params: params,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor(
    private eventsStore: EventsStore,
    private rest: EventsRestService,
    private devicesQuery: DevicesQuery
  ) {}

  public upsertMany(events: TCEvent[]) {
    this.eventsStore.upsertMany(events);
  }

  public load(
    deviceId?: number[],
    groupId?: number[],
    type?: string[],
    from?: string,
    to?: string
  ): Observable<TCEvent[]> {
    if (!to || 0 === to.length) {
      to = moment.utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }
    if (!from || 0 === from.length) {
      from =
        moment
          .utc()
          .startOf('day')
          .add(-6, 'months')
          .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }

    return this.rest.getItems(deviceId, groupId, type, from, to).pipe(
      tap((events) => {
        const evIds = arrayDistinct(events, (a, b) => a.id === b.id);
        this.upsertMany(evIds);
      })
    );
  }

  public updateFilter(filter: Partial<EventFilter>) {
    this.eventsStore.update({
      filter: filter,
    });
  }

  public setActive(eventId: number | null) {
    this.eventsStore.setActive(eventId);
  }
}
