import { share } from 'rxjs/operators';
import { InjectionToken } from '@angular/core';
import { EMPTY, Observable, Observer, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AnonymousSubject } from 'rxjs/internal/Subject';

export let wsBaseURL = new InjectionToken<string>('wsBaseURL');
export let wsTimeout = new InjectionToken<number>('wsTimeout');

// Based on https://medium.com/@lwojciechowski/websockets-with-angular2-and-rxjs-8b6c5be02fac#.r2xlpj4q0
export class WebSocketService {
  private subject: Subject<any> | undefined;

  constructor(private cookieSvc: CookieService) {}

  protected initUserCredentials() {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (!!user.username) {
      this.cookieSvc.set('user', window.btoa(user.username), undefined, '/');
    }
    if (!!user.password) {
      this.cookieSvc.set('password', window.btoa(user.password), undefined, '/');
    }
  }

  public send(data: any) {
    if (this.subject) this.subject.next(data);
  }

  public asObservable(): Observable<any> {
    return (this.subject) ? this.subject.asObservable().pipe(share()) : EMPTY; 
  }

  public disconnect() {
    if (this.subject) this.subject.complete();
  }

  public connect(url: string, onOpen?: (e: any) => void): Observable<any> {
    if (!this.subject) {
      this.subject = this.create(url, onOpen);
    }
    return this.asObservable();
  }

  protected create(url: string, onOpen?: (e: any) => void): Subject<any> {
    //        this.cookieSvc.set( 'user', 'ZGRpbWl0cm92QG9rdGltYS5jb20%3D' );
    //        this.cookieSvc.set( 'password', 'G9pcm90' );
    this.initUserCredentials();

    let ws: any;
    const observable = new Observable((obs: Observer<any>) => {
      console.log('Connecting to: ', url);
      ws = new WebSocket(
        url.replace('http://', 'ws://').replace('https://', 'wss://')
      );

      ws.onopen = (e: any) => {
        if (onOpen) {
          onOpen(e);
        }
        //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: true });
      };
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = (e: any) => {
        //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: false });
        obs.error(e);
      };
      ws.onclose = () => {
        //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: false });
        obs.complete();
      };
      return ws.close.bind(ws);
    });

    const observer: Observer<any> = {
      next: (data: Object) => {
        if (!!ws && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: (err) => {
        console.log('Error connecting to the ws: ', err);
      },
      complete: () => {
        if( !!ws) ws.close();
      },
    };

    return new AnonymousSubject(observer, observable);
  }
} // end class WebSocketService
