<div fxLayout="row" class="board">
    <div fxLayout="column" fxFlex="1 1 30%" fxLayoutGap="1em" class="action-column">

        <mat-tab-group>
            <mat-tab label="Events">
                <fod2-events-list (eventSelected)="onEventSelected($event)"></fod2-events-list>
            </mat-tab>
            <mat-tab label="Visits">
                <app-visits-overview [map]="mapCmponent" (visitSelected)="onVisitSelected($event)"></app-visits-overview>
            </mat-tab>
            <mat-tab label="Devices">
                <fod2-devices-overview [map]="mapCmponent" (deviceSelected)="onDeviceSelected($event)"></fod2-devices-overview>
            </mat-tab>
        </mat-tab-group>

    </div>

    <app-map #mapCmponent fxFlex="1 1 70%"></app-map>
    <labs-toast></labs-toast>

</div>
