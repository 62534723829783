import {TCVisitFull} from './../state/visits/visit.model';
import {GeofencesQuery} from './../state/geofences/geofences.query';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
  tap,
  map,
  filter,
  share,
  finalize,
  catchError,
  mergeMap,
  retry,
  repeat,
  withLatestFrom,
} from 'rxjs/operators';
import {Observable, concat, EMPTY, from, defer, timer, of, BehaviorSubject, merge, combineLatest} from 'rxjs';
import {
  ServiceLocator,
  WebSocketService,
  wsBaseURL,
  wsTimeout,
} from '../services';
import {TCUser} from './dto/tc-user';
import {CookieService} from 'ngx-cookie-service';
import {dateReviver, onlyAlarmTypeEvents, playAudio} from '../utils/utils';
import {DevicesService, DevicesQuery, TCDevice} from '../state/devices';
import {
  PositionsQuery,
  TCPosition,
  PositionsService,
} from '../state/positions';
import {TCGeofence, GeofencesService} from '../state/geofences';
import {tcBaseURL} from './trac-car.abstract.rest.service';
import {Router} from '@angular/router';
import {EventsService, TCEvent} from '../state/events';
import {GroupsService} from '../state/groups';
import {isNil} from '@datorama/akita';
import {VisitsQuery, VisitsService} from '../state/visits';
import {DriversService} from '../state';
import {AuthenticationService} from '../services/authentication.service';
import * as moment from "moment/moment";

@Injectable({
  providedIn: 'root',
})
export class TracCarService {
  public get positions$(): Observable<TCPosition[]> {
    if (!this.connected) {
      this.wsConnect();
    }

    return this.positionsQuery.selectAll();
  }

  public get devices$(): Observable<TCDevice[]> {
    if (!this.connected) {
      this.wsConnect();
    }

    return this.devicesQuery.selectAll();
  }

  constructor(private router: Router) {
    this.httpClient = ServiceLocator.injector.get(HttpClient);
    this._baseUrl = ServiceLocator.injector.get(tcBaseURL);

    const cookieSvc = ServiceLocator.injector.get(CookieService);
    this.wsService = new WebSocketService(cookieSvc);
    this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
    this.wsTimeout = ServiceLocator.injector.get(wsTimeout);

    this.devicesQuery = ServiceLocator.injector.get(DevicesQuery);
    this.positionsQuery = ServiceLocator.injector.get(PositionsQuery);
    this.geoFencesQuery = ServiceLocator.injector.get(GeofencesQuery);
    this.visitsQuery = ServiceLocator.injector.get(VisitsQuery);

    this.groupsSvc = ServiceLocator.injector.get(GroupsService);

    this.devicesSvc = ServiceLocator.injector.get(DevicesService);
    this.positionsSvc = ServiceLocator.injector.get(PositionsService);
    this.geofencesSvc = ServiceLocator.injector.get(GeofencesService);
    this.eventsSvc = ServiceLocator.injector.get(EventsService);
    this.visitsSvc = ServiceLocator.injector.get(VisitsService);
    this.driversSvc = ServiceLocator.injector.get(DriversService);

    if (this.isLoggedIn()) {
      this.wsConnect();
    }
  }

  protected httpClient: HttpClient;
  protected _baseUrl: string;

  protected wsService: WebSocketService;
  protected wsBaseUrl: string;
  protected wsTimeout = 5000;

  protected connected = false;

  protected positionsQuery: PositionsQuery;
  protected devicesQuery: DevicesQuery;
  protected geoFencesQuery: GeofencesQuery;
  protected visitsQuery: VisitsQuery;

  protected groupsSvc: GroupsService;
  protected devicesSvc: DevicesService;
  protected positionsSvc: PositionsService;
  protected geofencesSvc: GeofencesService;
  protected eventsSvc: EventsService;
  protected visitsSvc: VisitsService;
  protected driversSvc: DriversService;

  private connecting = false;

  private visitDateFilter = new BehaviorSubject<{day?:moment.Moment}>({});

  isLoggedIn(): boolean {
    const user = localStorage.getItem('currentUser');
    return !!user;
  }

  protected wsReconnect() {
    this.wsDisconnect();
    this.wsConnect();
  }

  protected wsDisconnect() {
    this.wsService.disconnect();
    this.connected = false;
    this.connecting = false;
  }

  protected wsConnect() {
    if (this.connecting) {
      return;
    }

    this.connecting = true;
    const wsOnly$ = defer(() => {
      return this.wsService.connect(this.wsBaseUrl, () => {
        console.log('WS connected to: ', this.wsBaseUrl);
        this.connected = true;
        this.connecting = false;
      });
    });

    const ws$ = wsOnly$.pipe(
      map((msg: MessageEvent) => {
        console.log('TracCar data: ', msg.data);
        return JSON.parse(msg.data, dateReviver);
      }),
      catchError((err) => {
        // localStorage.removeItem('currentUser');
        // this.router.navigate(['home']);
        console.error(err);
        return EMPTY;
      }),
      retry({
        delay: (_: any, retryCount: number) =>
          retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
        resetOnSuccess: true,
      }),
      repeat(),
      finalize(() => {
        this.connected = false;
        this.connecting = false;
      }),
      share()
    );

    const groups$ = this.groupsSvc.load().pipe(
      mergeMap((groups) => from(groups)),
      share()
    );

    groups$
      .pipe(
        mergeMap((gr) => {
          if (Array.isArray(gr) && gr.length > 0) {
            return this.getGeoFences(undefined, gr[0].id);
          }

          return this.getGeoFences();
        })
      )
      .subscribe();

    concat(
      groups$.pipe(
        mergeMap((gr) => {
          return this.driversSvc.load(0, gr.id, 0, true, false);
        })
      ),
      ws$
    )
      .pipe(
        filter((data) => {
          return !!data.drivers;
        }),
        map((data) => data.drivers),
        tap((drivers) => {
          this.driversSvc.upsertMany(drivers);
        }),
        retry({
          delay: (_: any, retryCount: number) =>
            retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
          resetOnSuccess: true,
        })
      )
      .subscribe();

    merge(
      combineLatest([groups$, this.visitDateFilter.asObservable()]).pipe(
        mergeMap(([gr,day]) => {
          return this.visitsSvc.load(0, gr.id, 0, true, false, day.day);
        })
      ),
      ws$
    )
      .pipe(
        filter((data) => {
          return !!data.visits;
        }),
        map((data) => data.visits),
        mergeMap((visits) => {
          return this.visitsSvc.upsertMany(visits);
        }),
        retry({
          delay: (_: any, retryCount: number) =>
            retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
          resetOnSuccess: true,
        })
        //   )
      )
      .subscribe();

    concat(
      this.positionsSvc.load(undefined, undefined, undefined, undefined, true),
      ws$.pipe(
        filter((data) => !!data.positions),
        map((data) => data.positions),
        tap((pos) => {
          this.positionsSvc.upsertMany(pos);
        }),
        tap((pos) => {
          this.visitsSvc.updatePositions(pos);
        }),
        retry({
          delay: (_: any, retryCount: number) =>
            retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
          resetOnSuccess: true,
        })
      )
    ).subscribe();

    ws$
      .pipe(
        filter((data) => !!data.events),
        map((data) => data.events),
        withLatestFrom(
          this.geoFencesQuery.selectAll(),
          this.visitsQuery.selectCurrentVisits()
        ),
        tap(([events, geofences, visits]) => {
          if (this.shouldMakeAlarm(events, geofences, visits)) {
            playAudio();
          }
        }),
        map(([events, geofences]) => events),
        tap((events) => {
          this.eventsSvc.upsertMany(events);
        }),
        tap((events) => {
          this.visitsSvc.updateEvents(events);
        }),
        retry({
          delay: (_: any, retryCount: number) =>
            retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
          resetOnSuccess: true,
        })
      )
      .subscribe();

    concat(
      this.devicesSvc.load(),
      ws$.pipe(
        filter((data) => !!data.devices),
        map((data) => data.devices),
        tap((devices) => {
          this.devicesSvc.upsertMany(devices);
        }),
        mergeMap((devices: TCDevice[]) => {
          return from(devices).pipe(
            mergeMap((dev) => {
              return this.visitsSvc.load(dev.id);
            })
          );
        }),
        retry({
          delay: (_: any, retryCount: number) =>
            retryCount < 10 ? timer(retryCount * 1000) : timer(10000),
          resetOnSuccess: true,
        })
      )
    ).subscribe();
  }

  protected shouldMakeAlarm(
    events: TCEvent[],
    geofences: TCGeofence[],
    visits: TCVisitFull[]
  ): boolean {
    if (isNil(events) || events.length === 0) {
      return false;
    }

    const targetEvent = events
      .filter((ev) => visits.some((v) => v.deviceId === ev.deviceId))
      .filter((ev) => {
        // return onlyAlarmTypeEvents([ev], geofences, ['alarm', 'geofenceEnter']);
        return onlyAlarmTypeEvents([ev], geofences, ['geofenceEnter']);
      });

    return targetEvent.length > 0;
  }

  public startSession(email: string, password: string): Observable<TCUser> {
    const body: HttpParams = new HttpParams()
      .append('email', email)
      .append('password', password);
    return this.httpClient.post<TCUser>(this._baseUrl + '/session', body);
  }

  public endSession() {
    this.wsDisconnect();
  }

  public reconnect() {
    this.wsReconnect();
  }

  /**
   * Fetch a list of Events within the time period for the Devices or Groups
   * At least one deviceId or one groupId must be passed
   *
   * @param deviceId - if provided only events associated with this device
   * @param groupId - if provided only events associated with this group
   * @param type  - % can be used to return events of all types
   * @param from  - in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to    - in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   */
  public getEvents(
    deviceId: number[],
    groupId: number[],
    type: string[],
    from: string,
    to: string
  ): Observable<TCPosition[]> {
    let params = new HttpParams();
    if (deviceId) {
      deviceId.forEach((devId) => {
        params = params.append('deviceId', `${devId}`);
      });
    }

    if (groupId) {
      groupId.forEach((grId) => {
        params = params.append('groupId', `${grId}`);
      });
    }

    if (type) {
      type.forEach((t) => {
        params = params.append('type', t);
      });
    }

    if (from) {
      params = params.append('from', from);
    }
    if (to) {
      params = params.append('to', to);
    }

    return this.httpClient.get<TCPosition[]>(
      this._baseUrl + '/reports/events',
      {params: params}
    );
  }

  public getGeoFences(
    deviceId: number | undefined = undefined,
    groupId: number | undefined = undefined,
    refresh: boolean = false,
    userId: number | undefined = undefined,
    all: boolean | undefined = undefined
  ): Observable<TCGeofence[]> {
    return this.geofencesSvc.load(deviceId, groupId, userId, refresh, all);
  }

  public loadVisitsForDay(day?: moment.Moment) {
    this.visitDateFilter.next({day: day});
    // const groups$ = this.groupsQuery.selectAll().pipe(
    //   mergeMap((groups) => from(groups)),
    //   mergeMap((gr) => {
    //     return this.load(0, gr.id, 0, true, false, day);
    //   }),
    //   reduce((varr: TCVisitFull[], newVarr: TCVisitFull[]) => {
    //     return varr.concat(newVarr);
    //   }, []),
    //   take(1)
    // )
    //   .subscribe(
    //     {
    //       next: d => {},
    //       error: e => {},
    //       complete: () => {
    //         console.log('Completed');
    //       }
    //     }
    //   );
  }
}
