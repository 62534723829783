import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MapComponent } from '../map/map.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceLocator } from '../services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  TCDevice,
  DevicesQuery,
  PositionsService,
  TCEvent,
  TCVisitFull,
  createVisit,
  Fod2Service,
  VisitsStore,
} from '../state';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { latLng } from 'leaflet';
import { EventMarkerOptions } from '../map/event.draw';
import { akitaConfig, getAkitaConfig } from '@datorama/akita';
import {
  CdkConnectedOverlay,
  FlexibleConnectedPositionStrategy,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-map-board',
  templateUrl: './map-board.component.html',
  styleUrls: ['./map-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapBoardComponent implements OnInit, OnDestroy {
  @ViewChild(MapComponent)
  protected map!: MapComponent;

  @ViewChild(MapComponent)
  protected overlayContainer!: CdkConnectedOverlay;

  protected snackBar: MatSnackBar;
  protected dialog: MatDialog;

  public selectedDevices?: TCDevice[] = [];

  constructor(
    protected devicesQuery: DevicesQuery,
    protected positionSvc: PositionsService,
    protected fod2Svc: Fod2Service,
    protected visitStore: VisitsStore
  ) {
    this.snackBar = ServiceLocator.injector.get(MatSnackBar);
    this.dialog = ServiceLocator.injector.get(MatDialog);
  }

  ngOnInit() {
    // this.fod2Svc
    //   .load()
    //   .pipe(untilDestroyed(this))
    //   .subscribe({
    //     next: (d: any) => {
    //       console.debug(`Successfuly loading data: ${d}`);
    //     },
    //     error: (e: any) => {
    //       console.error(`Error loading data: ${e}`);
    //     },
    //     complete: () => {
    //       //this.positionToDevices([]);
    //     },
    //   });
  }

  ngOnDestroy() {}

  public get devices$(): Observable<TCDevice[]> {
    return this.devicesQuery.selectAll();
  }

  public onVisitSelected(visit: TCVisitFull) {
    this.fod2Svc.setActiveVisit(visit?.id || null);
  }

  public onEventSelected(event: TCEvent) {
    this.fod2Svc.setActiveEvent(event?.id || null);
  }

  public onDeviceSelected(device?: TCDevice) {
    this.fod2Svc.setActiveDevice(device?.id || null);
  }
}
