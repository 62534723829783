export interface TCDriver {
    id: number;
    name: string;
    uniqueId: string;
    attributes: any;
}

export type TCDriverWithDevice = TCDriver & {deviceId: number}

export function createDriver(params: Partial<TCDriver>) {
  return {

  } as TCDriver;
}
