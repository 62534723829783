import { HttpClient } from '@angular/common/http';
import { ServiceLocator } from '../services';
import { tcBaseURL } from './trac-car.abstract.rest.service';
import { Observable } from 'rxjs';


export abstract class AbstractReportRestService<Type extends Partial<{id: number}>> {

    protected httpClient: HttpClient;
    protected _baseUrl: string;

    constructor() {
        this.httpClient = ServiceLocator.injector.get( HttpClient );
        this._baseUrl = ServiceLocator.injector.get( tcBaseURL );
    }

    public abstract getItems( deviceId: number[], groupId: number[], type: string[], from: string, to: string ): Observable<Type[]>;
}

