<h1 mat-dialog-title>{{title}}</h1>
<mat-divider></mat-divider>

<div mat-dialog-content>{{message}}</div>          
        
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)" type="button">OK</button>
    <button mat-raised-button (click)="dialogRef.close(false)" type="button">Cancel</button>
</div>
