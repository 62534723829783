import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TCDriver, TCDevice, DevicesQuery, TCDriverWithDevice } from 'src/app/state';
import { AbstractFormComponent } from 'src/app/components';
import { isNil } from '@datorama/akita';

import * as uuid from 'uuid';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-visitor-form',
    templateUrl: './visitor-form.component.html',
    styleUrls: ['./visitor-form.component.scss']
})
export class VisitorFormComponent extends AbstractFormComponent<VisitorFormComponent, TCDriverWithDevice> {

    public devices$: Observable<TCDevice[]>;

    constructor(public override dialogRef: MatDialogRef<VisitorFormComponent>, @Inject(MAT_DIALOG_DATA) public data: TCDriverWithDevice, protected devicesQ: DevicesQuery) {
        super(dialogRef, data);

        this.devices$ = devicesQ.selectAvailable();
    }

    protected initForm(fb: FormBuilder): FormGroup {
        const f = fb.group({
            id: '',
            name: ['', [Validators.required]],
            attributes: fb.group({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                company: ''
            }),
            deviceId: ['', [Validators.required]],
        });

        return f;
    }

    public override save(item: TCDriverWithDevice) {
        const driver = item || ({} as TCDriverWithDevice);
        if (isNil(driver.uniqueId)) {
            driver.uniqueId = uuid.v4();
        }
        return super.save(driver);
    }

}
