<h1 mat-dialog-title>Visitor info</h1>

<div *ngIf="(devices$ | async) as devices">
    <div mat-dialog-content fxLayout="column" fxLayoutGap="20px" class="form-content" [formGroup]="form"
        *ngIf="devices.length > 0; else noDevices">
        <!-- Item specifics start here -->
        <input type="hidden" id="id" name="id" formControlName="id" />

        <div fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
                <mat-form-field fxFlex> <input matInput placeholder="Name" type="text" id="name" name="name"
                        formControlName="name" /> </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Devices</mat-label>
                    <mat-select formControlName="deviceId" [multiple]="false">
                        <mat-option *ngFor="let dev of devices" [value]="dev.id">{{dev.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start" formGroupName="attributes">
                <mat-form-field fxFlex> <input matInput placeholder="First name" type="text" id="firstName"
                        name="firstName" formControlName="firstName" /> </mat-form-field>
                <mat-form-field fxFlex> <input matInput placeholder="Last name" type="text" id="lastName"
                        name="lastName" formControlName="lastName" /> </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start" formGroupName="attributes">
                <mat-form-field fxFlex> <input matInput placeholder="Email" type="email" id="email" name="email"
                        formControlName="email" /> </mat-form-field>
                <mat-form-field fxFlex> <input matInput placeholder="Phone" type="text" id="phone" name="phone"
                        formControlName="phone" /> </mat-form-field>
                <mat-form-field fxFlex> <input matInput placeholder="Company" type="text" id="company" name="company"
                        formControlName="company" /> </mat-form-field>
            </div>
        </div>

        <ul fxFlex="0 0 auto" fxLayout="column" *ngIf="errorMsgs?.length" class="alert alert-danger">
            <li *ngFor="let err of errorMsgs">{{err}}</li>
        </ul>
        <!-- Item specifics end here -->
    </div>

    <mat-divider></mat-divider>
    <div mat-dialog-actions fxLayout="row" fxLayoutGap="20px" class="form-action">
        <button mat-raised-button color="primary" (click)="save(selectedItem)" type="button"
            [disabled]="!isValid">Submit</button>
        <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
    </div>

    <div class="ui-g-12" *ngIf="debug">
        <pre>{{ debug | json }}</pre>
    </div>
</div>


<ng-template #noDevices>
    <div mat-dialog-content>
        <p>No available trackers. Try again later!</p>
    </div>
</ng-template>