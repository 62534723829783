<h1 mat-dialog-title>Device info</h1>

<div mat-dialog-content fxLayout="column" fxLayoutGap="20px" class="form-content" [formGroup]="form">
    <!-- Item specifics start here -->
    <input type="hidden" id="id" name="id" formControlName="id" />

    <div fxLayout="column" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
            <mat-form-field fxFlex> <input matInput placeholder="Name" type="text" id="name" name="name"
                    formControlName="name" /> </mat-form-field>
            <mat-form-field fxFlex> <input matInput placeholder="Identifier" type="text" id="uniqueId" name="uniqueId"
                    formControlName="uniqueId" /> </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
            <mat-form-field fxFlex>
                <mat-label>Group</mat-label>
                <mat-select formControlName="groupId" [multiple]="false">
                    <mat-option *ngFor="let gr of (groups$ | async)" [value]="gr.id">{{gr.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Category</mat-label>
                <mat-select formControlName="category" [multiple]="false">
                    <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex> 
                <input matInput placeholder="Tracker phone Nr" type="text" id="phone" name="phone" formControlName="phone" /> 
            </mat-form-field>
        </div>
    </div>

    <ul fxFlex="0 0 auto" fxLayout="column" *ngIf="errorMsgs?.length" class="alert alert-danger">
        <li *ngFor="let err of errorMsgs">{{err}}</li>
    </ul>
    <!-- Item specifics end here -->
</div>

<mat-divider></mat-divider>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="20px" class="form-action">
    <button mat-raised-button color="primary" (click)="save(selectedItem)" type="button"
        [disabled]="!isValid">Submit</button>
    <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
</div>

<div class="ui-g-12" *ngIf="debug">
    <pre>{{ debug | json }}</pre>
</div>