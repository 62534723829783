export interface TCPosition {
    readonly id: number;
    readonly deviceId: number;
    readonly protocol: string;
    readonly deviceTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
    readonly fixTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
    readonly serverTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
    outdated: boolean;
    valid: boolean;
    readonly latitude: number;
    readonly longitude: number;
    readonly altitude: number;
    readonly speed: number;  //  in knots
    readonly course: number;
    address: string;
    accuracy: number;
    network: string;
    attributes: object;
}

export function createPosition( params: Partial<TCPosition> ) {
    return {
        ...params
    } as TCPosition;
}
