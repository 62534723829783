export interface TCGroup {
  id: number;
  groupId: number;
  name: string;
  attributes: object;
}

export function createGroup(params: Partial<TCGroup>) {
  return {
    ...params
  } as TCGroup;
}
