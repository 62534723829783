import { TCGeofence } from './../state/geofences/geofence.model';
import { Fod2Query, DeviceAndVisit } from './../state/fod2/fod2.query';
import { Fod2Service } from './../state/fod2/fod2.service';
import { GeoFenceDraw } from './geofence.draw';
// https://github.com/Asymmetrik/ngx-leaflet

import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import {
  tileLayer,
  latLng,
  Layer,
  LatLngBounds,
  LatLng,
  LayerGroup,
  LatLngBoundsLiteral,
  FeatureGroup,
  MapOptions,
} from 'leaflet';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  tap,
} from 'rxjs/operators';
import {
  combineLatest,
  interval,
  BehaviorSubject,
  Observable,
  of as observableOf,
} from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  TCDevice,
  PositionsQuery,
  DevicesQuery,
  GeofencesQuery,
  TCVisitFull,
  VisitsQuery,
  DriversQuery,
  createVisit,
  TCEvent,
} from '../state';

//import { VectorMarkers } from 'Leaflet.vector-markers';
import * as moment from 'moment';

import { arrayEquals } from '../utils';
import { VisitDraw } from './visit.draw';
import { EventColor, EventDraw, EventMarkerOptions } from './event.draw';
import { DeviceDraw } from './device.draw';

// export type VisitAndRoute = { visit?: TCVisitFull; route: TCPosition[] };

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  public layersControl: any = null;
  protected _zoom = 14;
  protected _center: LatLng = latLng(50.844571960481055, 4.391028285026551);

  public layers: Layer[] = [];
  public latLngBounds: LatLngBounds = new LatLngBounds([
    [50.847126, 4.387525],
    [50.847099, 4.398319],
    [50.842221, 4.398426],
    [50.842316, 4.387504],
  ]);

  private geoRoutes: LayerGroup = new LayerGroup();

  private deviceMarkers: LayerGroup = new LayerGroup();
  private geoFences: LayerGroup = new LayerGroup();
  private visitPath: LayerGroup = new LayerGroup();
  private eventMarkers: FeatureGroup = new FeatureGroup();

  private visitDraw: VisitDraw;
  private eventDraw: EventDraw;
  private deviceDraw: DeviceDraw;
  private geoFenceDraw: GeoFenceDraw;

  private activeEvents$ = new BehaviorSubject<EventMarkerOptions[]>([]);

  public get center() {
    return this._center;
  }

  public set center(value: LatLng) {
    this._center = value;
  }

  public get zoom(): number {
    return this._zoom;
  }

  public set zoom(value: number) {
    this._zoom = value;
  }

  constructor(
    private zone: NgZone,
    protected positionsQuery: PositionsQuery,
    protected devicesQuery: DevicesQuery,
    protected geoFencesQuery: GeofencesQuery,
    protected visitsQuery: VisitsQuery,
    protected driversQuery: DriversQuery,
    protected fod2Svc: Fod2Service,
    protected fod2Query: Fod2Query
  ) {
    this.visitDraw = new VisitDraw(this.visitPath);
    this.eventDraw = new EventDraw(this.eventMarkers);
    this.deviceDraw = new DeviceDraw(this.deviceMarkers);
    this.geoFenceDraw = new GeoFenceDraw(this.geoFences);
  }

  public options: MapOptions = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '<a href="http://seris.be">Seris Technology FOB2</a>',
      }),
    ],
    zoom: 14,
    center: latLng(50.844571960481055, 4.391028285026551),
  };

  ngOnInit() {
    this.layersControl = {
      baseLayers: {
        'Open Street Map': tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: '<a href="http://seris.be">Seris Technology FOB2</a>',
          }
        ),
        'Thunder Forest Map': tileLayer(
          'https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=b1dda05e8ca647328224e0838408aef2',
          {
            maxZoom: 18,
            attribution: '<a href="http://seris.be">Seris Technology FOB2</a>',
          }
        ),
      },
      overlays: {
        Devices: this.deviceMarkers,
        Routes: this.geoRoutes,
        'Geo Fences': this.geoFences,
        Visit: this.visitPath,
        Events: this.eventMarkers,
      },
    };

    this.layers = [
      this.deviceMarkers,
      this.geoFences,
      this.visitPath,
      this.eventMarkers,
    ];

    this.setupDevicesObs();
    this.setupGeoFencesObs();
    this.setupEventsObs();
  }

  ngOnDestroy(): void {}

  protected setupDevicesObs() {
    const devices$ = combineLatest([
      this.fod2Query.visitsToShow$,
      this.eventVisits$,
    ]).pipe(
      map(([devices, events]) => {
        return events.length > 0 ? events : devices;
      })
    );

    const geoFences$ = this.geoFencesQuery.selectAll();

    combineLatest([devices$, geoFences$])
      .pipe(
        tap(([devices, fences]) => {
          const visit = devices.length === 1 ? devices[0].visit : undefined;
          if (!visit?.endTime) {
            this.deviceDraw.showDevices(devices, fences);
          } else {
            this.deviceDraw.clearDevices();
          }
          this.visitDraw.showVisit(visit);
        }),
        tap(([devices, _]) => {
          if (devices.length === 1) {
            const dev = devices[0];
            const devPos =
              dev.position && !dev.visit?.endTime ? [dev.position] : [];
            const visitRoute = dev.visit?.route || [];
            const pos: LatLngBoundsLiteral = [...devPos, ...visitRoute].map(
              (p) => [p.latitude, p.longitude]
            );
            this.setMapPosition(pos);
          } else {
            const pos: LatLngBoundsLiteral = devices
              .filter((d) => d.position)
              .map((d) => d.position!)
              .map((p) => [p.latitude, p.longitude]);
            this.setMapPosition(pos);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (_) => {},
        error: (err) => {
          console.log('Devices error: ', err);
        },
        complete: () => {
          console.log('Devices completed ');
        },
      });
  }

  protected setupGeoFencesObs() {
    this.geoFencesQuery
      .selectAll()
      .pipe(
        tap((gf) => this.geoFenceDraw.refreshGeoFences(gf)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (rt) => {
          console.log('GeoFence: ', rt);
        },
        error: (err) => {
          console.log('GeoFence error: ', err);
        },
        complete: () => {
          console.log('GeoFence completed ');
        },
      });
  }

  protected setMapPosition(bounds: LatLngBoundsLiteral) {
    if (bounds?.length > 0) {
      this.latLngBounds = new LatLngBounds(bounds);
    } else {
      const user: any = JSON.parse(localStorage.getItem('currentUser') || '{}');
      if (user?.longitude && user?.latitude) {
        const DIST = Math.max(0.003, 0.028 - 0.0015 * (user?.zoom || 14)); //0.007;
        this.latLngBounds = new LatLngBounds([
          [user.latitude + DIST, user.longitude + DIST],
          [user.latitude + DIST, user.longitude - DIST],
          [user.latitude - DIST, user.longitude - DIST],
          [user.latitude - DIST, user.longitude + DIST],
        ]);
        // this.center = latLng(user.latitude, user.longitude);
        // this.zoom = 7;//user?.zoom || 14;
      } else {
        this.latLngBounds = new LatLngBounds([
          [50.847126, 4.387525],
          [50.847099, 4.398319],
          [50.842221, 4.398426],
          [50.842316, 4.387504],
        ]);
      }
    }
  }

  protected showEvent(event?: TCEvent) {
    if (!event) {
      this.activeEvents$.next([]);
      return;
    }

    const options: EventMarkerOptions = {
      eventId: event.id,
      time: moment(event.eventTime),
      type: event.type,
      deviceId: event.deviceId,
      expires: moment().add(10, 'seconds'),
      color: 'red',
    };

    if (!event.positionId) {
      return this.activeEvents$.next([options]);
    }

    this.fod2Svc
      .loadPositions([event.positionId])
      .pipe(
        map((poss) => {
          if (poss.length > 0) {
            const pos = poss[0];
            return {
              ...options,
              position: latLng(pos.latitude, pos.longitude),
            };
          }
          return options;
        }),
        untilDestroyed(this)
      )
      .subscribe((opt) => {
        this.activeEvents$.next([opt]);
      });
  }

  protected get eventVisits$(): Observable<DeviceAndVisit[]> {
    return combineLatest([
      this.activeEvents$.asObservable(),
      this.fod2Query.allVisits$,
    ]).pipe(
      map(([evs, visits]) => {
        const evVisits = evs
          .map((ev) => {
            return visits.find((v) =>
              v.visit?.events?.some((e) => e.id === ev.eventId)
            );
          })
          .filter((x) => !!x)
          .map((x) => x!);

        return evVisits;
      })
    );
  }

  protected setupEventsObs() {
    this.fod2Query.activeEvent$.pipe(untilDestroyed(this)).subscribe((ev) => {
      this.showEvent(ev);
    });

    const distEvents$ = this.activeEvents$
      .asObservable()
      .pipe(
        distinctUntilChanged((a, b) =>
          arrayEquals(a, b, (x, y) => x.eventId === y.eventId)
        )
      );

    combineLatest([distEvents$, interval(1000)])
      .pipe(
        untilDestroyed(this),
        map(([evs, i]) => {
          return evs
            .filter((ev) => moment() < ev.expires)
            .map((ev) => {
              const newColor: EventColor = 0 === i % 2 ? 'red' : 'blue';
              return {
                ...ev,
                color: newColor,
              };
            });
        }),
        tap((evOpt) => {
          this.eventDraw.createEventMarkers(evOpt);
        })
      )
      .subscribe((evs) => {
        if (evs.length === 0) {
          this.fod2Svc.setActiveEvent(null);
        }
      });
  }
}
