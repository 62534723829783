<div
  *ngFor="let item of toastService.items$ | async"
  @verticalCollapse
  @triggerChildAnimation
>
  <labs-toast-item
    [toast]="item"
    (toastClosed)="onToastClosed($event)"
    @slideIn
    @slideOut
  >
  </labs-toast-item>
</div>
