import { DevicesStore } from './devices.store';
import { Injectable } from '@angular/core';
import { TCDevice } from './device.model';
import { TracCarAbstractRestService } from 'src/app/trac-car/trac-car.abstract.rest.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { isNil, coerceArray } from '@datorama/akita';
import { addHttpParam } from 'src/app/utils';

@Injectable({ providedIn: 'root' })
class DevicesRestService extends TracCarAbstractRestService<TCDevice> {
  constructor() {
    super();
  }

  protected get url(): string {
    return '/devices';
  }
}

@Injectable({ providedIn: 'root' })
export class DevicesService {
  constructor(
    private devicesStore: DevicesStore,
    private rest: DevicesRestService
  ) {}

  public upsertMany(devices: TCDevice[]) {
    this.devicesStore.upsertMany(devices);
  }

  public load(
    id?: number | number[],
    uniqueId?: string | string[],
    userId?: number,
    all?: boolean
  ): Observable<TCDevice[]> {
    let params = new HttpParams();
    params = addHttpParam(params, 'id', id);
    params = addHttpParam(params, 'uniqueId', uniqueId);
    params = addHttpParam(params, 'userId', userId);
    params = addHttpParam(params, 'all', all);

    return this.rest.read({ params: params }).pipe(
      tap((devs) => {
        this.devicesStore.set(devs);
      })
    );
  }

  public save(device: TCDevice): Observable<TCDevice> {
    if (isNil(device.id)) {
      return this.rest.create(device);
    } else {
      return this.rest.update(device);
    }
  }

  public delete(device: TCDevice): Observable<object> {
    return this.rest.erase(device.id);
  }

  public setActive(deviceId: number | null) {
    this.devicesStore.setActive(deviceId);
  }
}
