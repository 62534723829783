import {TCEvent} from './../events/event.model';
import {VisitsStore, VisitFilter} from './visits.store';
import {Injectable} from '@angular/core';
import {TCVisit, TCVisitFull} from './visit.model';
import {
  HttpOptions,
  TracCarAbstractRestService,
} from 'src/app/trac-car/trac-car.abstract.rest.service';
import {Observable, EMPTY, of, combineLatest, concat, range, from, timer, reduce, BehaviorSubject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {
  addHttpParam,
  arrayDistinct,
  onlyDistinctPositions,
  positionsTimeComparator,
} from 'src/app/utils';
import {tap, map, mergeMap, finalize, scan, retry, take} from 'rxjs/operators';
import {isNil} from '@datorama/akita';
import * as moment from 'moment';
import {VisitsQuery} from './visits.query';
import {TCPosition} from '../positions/position.model';
import {createVisit} from '.';
import {angleFromCoordinate} from 'src/app/map/map.utils';
import {EventsService} from '../events/events.service';
import {environment} from "../../../environments/environment";
import {GroupsQuery} from "../groups";

@Injectable({providedIn: 'root'})
class VisitsRestService extends TracCarAbstractRestService<TCVisit> {
  constructor() {
    super();
  }

  protected get url(): string {
    return '/visits';
  }

  public getRoute(options?: HttpOptions): Observable<TCPosition[]> {
    return this.httpClient.get<TCPosition[]>(
      this._baseUrl + '/reports/route',
      options
    );
  }
}

@Injectable({providedIn: 'root'})
export class VisitsService {

  private dateFilter = new BehaviorSubject<{day?:moment.Moment}>({});

  constructor(
    private rest: VisitsRestService,
    private visitsStore: VisitsStore,
    private visitsQuery: VisitsQuery,
    private eventsSvc: EventsService,
    private groupsQuery: GroupsQuery
  ) {
  }

  protected mapToVisitsFull(
    visits: TCVisit[],
    groupId?: number,
    date?: moment.Moment
  ): Observable<TCVisitFull[]> {

    const time7DaysAgo = moment().add(-environment.lastDaysVisits, "days");
    const dQuery = date?.clone().startOf('day').add(1, 'minute');

    visits = visits
      .sort((v1: TCVisit, v2: TCVisit) => {
        return v1.startTime > v2.startTime ? -1 : 1;
      })
      .filter(v => {
        const start = moment(v.startTime).startOf('day');
        const end = (v.endTime ? moment(v.endTime) : moment()).endOf('day');
        const res = dQuery ? dQuery.isBetween(start, end) : start.isAfter(time7DaysAgo);
        return res;
      });

    return range(0, visits.length / 5).pipe(
      mergeMap(idx => {
        const maxIndex = Math.min((idx + 1) * 5, visits.length);
        const visitArray = visits.slice(idx * 5, maxIndex);
        return this.mapToVisitsInt(visitArray, groupId);
      }),
      scan((varr: TCVisitFull[], newVarr: TCVisitFull[]) => {
        return varr.concat(newVarr);
      }, [])
    );
  }


  public load(
    deviceId?: number,
    groupId?: number,
    userId?: number,
    refresh?: boolean,
    all?: boolean,
    date?: moment.Moment
  ): Observable<TCVisitFull[]> {
    let params = new HttpParams();
    params = addHttpParam(params, 'deviceId', deviceId);
    params = addHttpParam(params, 'groupId', groupId);
    params = addHttpParam(params, 'userId', userId);
    params = addHttpParam(params, 'refresh', true); // refresh);
    params = addHttpParam(params, 'all', all);

    return this.rest.read({params: params}).pipe(
      mergeMap((visits) => {
        return this.mapToVisitsFull(visits, groupId, date);
      }),
      tap((visits) => {
        this.visitsStore.upsertMany(visits);
      })
    );
  }

  protected mapToVisitsInt(
    visits: TCVisit[],
    groupId?: number
  ): Observable<TCVisitFull[]> {
    const devices = arrayDistinct(
      visits.map((v) => v.deviceId),
      (a, b) => a === b
    );
    let firstTime = moment();
    let posFirstTime = moment();
    visits.forEach((v) => {
      const vStart = moment(v.startTime);
      if (vStart.isValid()) {
        if (vStart < firstTime) {
          firstTime = vStart;
        }

        if (!v.endTime && vStart < posFirstTime) {
          posFirstTime = vStart;
        }
      }
    });

    const lastTime = moment();
    const groups = groupId ? [groupId] : [];
    const positions$ = this.getRoute(devices, groups, posFirstTime, lastTime);
    const events$ = this.getEvents(devices, firstTime, lastTime);

    const enrichedVisits$ = combineLatest([positions$, events$]).pipe(
      map(([positions, events]) => {
        return visits.map((v) => {
          return this.getRouteAndEvents(v, positions, events);
        });
      })
    );

    return enrichedVisits$;
  }

  public save(
    visit: TCVisitFull,
    groupId: number | null
  ): Observable<TCVisitFull> {
    const {events, route, ...toSave} = visit;
    const obs$ = !visit.id
      ? this.rest.create(toSave)
      : this.rest.update(toSave);
    return obs$.pipe(
      map((v) => ({...v, route: route, events: events})),
      mergeMap((v) => {
        return this.rest
          .createPermission({
            deviceId: v.deviceId,
            visitId: v.id,
          })
          .pipe(map((_) => v));
      }),
      mergeMap((v) => {
        if (!isNil(groupId)) {
          return this.rest
            .createPermission({groupId: groupId, visitId: v.id})
            .pipe(map((_) => v));
        }
        return of(v);
      }),
      tap((v) => {
        this.visitsStore.upsert(v.id, v);
      })
    );
  }

  public startVisit(
    deviceId: number,
    visitorId: number,
    groupId: number
  ): Observable<TCVisitFull> {
    const visit = createVisit({
      deviceId: deviceId,
      driverId: visitorId,
      startTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    });

    return this.save(visit, groupId);
  }

  public endVisit(visitId: number): Observable<TCVisitFull> {
    const v = this.visitsQuery.getEntity(visitId);
    if (isNil(v)) {
      return EMPTY;
    }
    const visit = {
      ...v,
      endTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    } as TCVisitFull;

    return this.save(visit, null);
  }

  public updateFilter(filter: Partial<VisitFilter>) {
    this.visitsStore.update({
      filter: filter,
    });
  }

  public getRouteAndEvents(
    visit: TCVisit,
    pos: TCPosition[],
    events: TCEvent[]
  ): TCVisitFull {
    const vStart = moment(visit.startTime);
    const vEnd = moment(visit.endTime);

    let route = pos.filter(
      (p) =>
        p.deviceId === visit.deviceId &&
        this.timeWithinInterval(moment(p.fixTime), vStart, vEnd)
    );
    const visitEvents = events.filter(
      (ev) =>
        ev.deviceId === visit.deviceId &&
        this.timeWithinInterval(moment(ev.eventTime), vStart, vEnd)
    );

    route = this.calcDirections(
      onlyDistinctPositions(route).sort(positionsTimeComparator)
    );
    return {
      ...visit,
      route: route,
      events: visitEvents,
    };
  }

  /**
   * Fetch a list of Positions within the time period for the Devices or Groups
   * At least one deviceId or one groupId must be passed
   */
  private getRoute(
    devices: number[],
    groups: number[],
    startTime: moment.Moment,
    endTime: moment.Moment
  ): Observable<TCPosition[]> {
    let params = new HttpParams();

    const start = startTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const to = endTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    params = addHttpParam(params, 'deviceId', devices);
    params = addHttpParam(params, 'groupId', groups);
    params = addHttpParam(params, 'from', start);
    params = addHttpParam(params, 'to', to);
    return this.rest.getRoute({params: params});
  }

  /**
   * Fetch a list of Events within the time period for the Devices or Groups
   * At least one deviceId or one groupId must be passed
   */
  private getEvents(
    devices: number[],
    startTime: moment.Moment,
    endTime: moment.Moment
  ): Observable<TCEvent[]> {
    const start = startTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const to = endTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    return this.eventsSvc.load(devices, [], undefined, start, to);
  }

  public updatePositions(pos: TCPosition[]) {
    pos.forEach((p) => {
      const visits = this.visitsQuery
        .getAll({
          filterBy: (visit) =>
            visit.deviceId === p.deviceId &&
            this.timeWithinVisit(p.fixTime, visit),
        })
        .map((v) => {
          const vRoute = [...(v.route || []), p];
          const route = this.calcDirections(
            onlyDistinctPositions(vRoute).sort(positionsTimeComparator)
          );
          return {
            ...v,
            route: route,
          };
        });

      if (visits.length > 0) this.visitsStore.upsertMany(visits);
    });
  }

  public updateEvents(events: TCEvent[]) {
    events.forEach((ev) => {
      const visits = this.visitsQuery
        .getAll({
          filterBy: (visit) =>
            visit.deviceId === ev.deviceId &&
            this.timeWithinVisit(ev.eventTime, visit),
        })
        .map((v) => {
          const vEvents = v.events || [];
          return {
            ...v,
            events: [...vEvents, ev],
          };
        });

      if (visits.length > 0) this.visitsStore.upsertMany(visits);
    });
  }

  protected calcDirections(route: TCPosition[]): TCPosition[] {
    return route.map((pos, i, ar) => {
      if (i == ar.length - 1 || !!pos.course) return pos;

      const direction = angleFromCoordinate(
        pos.latitude,
        pos.longitude,
        ar[i + 1].latitude,
        ar[i + 1].longitude
      );
      return {
        ...pos,
        course: direction,
      };
    });
  }

  public upsertMany(visits: TCVisit[]): Observable<TCVisitFull[]> {
    if (visits.length > 0) return EMPTY;

    return this.mapToVisitsFull(visits).pipe(
      tap((vs) => {
        this.visitsStore.upsertMany(vs);
      })
    );
  }

  private timeWithinVisit(p: any, v: TCVisit): boolean {
    const vStart = moment(v.startTime);
    const vEnd = moment(v.endTime);
    const pTime = moment(p);

    return this.timeWithinInterval(pTime, vStart, vEnd);
  }

  private timeWithinInterval(
    p: moment.Moment,
    start: moment.Moment,
    end: moment.Moment
  ): boolean {
    return (
      p.isValid() &&
      (!start.isValid() || start <= p) &&
      (!end.isValid() || end >= p)
    );
  }

  public setActive(visitId: number | null) {
    this.visitsStore.setActive(visitId);

    const visit = this.visitsQuery.getActive();

    if (visit && visit.route?.length === 0) {
      this.visitsStore.setLoading(true);
      const vStart = moment(visit.startTime);
      const vEnd = visit.endTime ? moment(visit.endTime) : moment();
      this.getRoute([visit.deviceId], [], vStart, vEnd)
        .pipe(
          tap((pos) => {
            this.updatePositionsOfVisit(visit, pos);
          }),
          finalize(() => {
            this.visitsStore.setLoading(false);
          })
        )
        .subscribe();
    }
  }

  public updatePositionsOfVisit(visit: TCVisitFull, pos: TCPosition[]) {
    const vStart = moment(visit.startTime);
    const vEnd = moment(visit.endTime);
    const vRoute = pos.filter(
      (p) =>
        p.deviceId === visit.deviceId &&
        this.timeWithinInterval(moment(p.fixTime), vStart, vEnd)
    );
    const route = this.calcDirections(
      onlyDistinctPositions(vRoute).sort(positionsTimeComparator)
    );
    const newVisit = {
      ...visit,
      route: route,
    };

    this.visitsStore.upsert(visit.id, newVisit);
  }

}
