import { PositionsStore } from './positions.store';
import { Injectable } from '@angular/core';
import { TCPosition } from './position.model';
import { TracCarAbstractRestService, HttpOptions } from 'src/app/trac-car/trac-car.abstract.rest.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { addHttpParam } from 'src/app/utils';

@Injectable({ providedIn: 'root' })
export class PositionsRestService extends TracCarAbstractRestService<TCPosition> {

    constructor() {
        super();
    }

    protected get url(): string {
        return '/positions';
    }
}


@Injectable({ providedIn: 'root' })
export class PositionsService {

    constructor(private positionsStore: PositionsStore, private rest: PositionsRestService) {
    }

    public upsertMany(positions: TCPosition[]) {
        this.positionsStore.upsertMany(positions);
    }

    public load(id?: number[], deviceId?: number, from?: string, to?: string, update: boolean = false): Observable<TCPosition[]> {

        let params = new HttpParams();
        params = addHttpParam(params, 'id', id);
        params = addHttpParam(params, 'deviceId', deviceId);
        params = addHttpParam(params, 'from', from);
        params = addHttpParam(params, 'to', to);
        return this.rest.read({ params: params }).pipe(
            tap(pos => {
                if (update) {
                    this.positionsStore.set(pos);
                }
            })
        );
    }
}

