import { TCEvent } from '../events/event.model';
import { TCPosition } from '../positions/position.model';

export interface TCVisit {
  readonly id: number;
  readonly deviceId: number;
  readonly driverId: number;
  readonly startPositionId: number;
  readonly endPositionId: number;
  readonly startTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
  readonly endTime: string; // (date-time) in IS0 8601 format. eg. 1963-11-22T18:30:00Z
  atributes: object;
}

export interface TCVisitFull extends TCVisit {
  route: TCPosition[];
  events: TCEvent[];
}

export function createVisit(params: Partial<TCVisitFull>) {
  return {
    ...params,
    id: params.id || 0,
    deviceId: params.deviceId || 0,
    route: params.route || [],
    events: params.events || [],
  } as TCVisitFull;
}
