import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Fod2Query } from '../state';

@Component( {
    selector: 'fod2-nav',
    templateUrl: './fod2-nav.component.html',
    styleUrls: ['./fod2-nav.component.scss']
} )
export class Fod2NavComponent implements OnInit {

    public loading$: Observable<boolean>;

    constructor( private breakpointObserver: BreakpointObserver, private basicAuth: AuthenticationService, private router: Router, private fod2Query: Fod2Query ) {
        this.loading$ = this.fod2Query.loading$.pipe(
            tap(load => {
                console.log(`Data loading: ${load}`);
            }),
            map( loading => {
              return this.isLoggedIn() ? loading : false;
            })
        );
    }

    ngOnInit() {
    }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe( Breakpoints.Handset )
        .pipe(
            map( result => result.matches )
        );

    // public isUserInRole( role: string ): boolean {
    //     return this.auth.isUserInRole( role );
    // }

    public isLoggedIn() {
        // return this.auth.isLoggedIn();
        return !!localStorage.getItem('currentUser')
    }

    public logout() {
        this.basicAuth.logout();
        window.location.reload();
    }
}
