import { PositionsQuery } from './../positions/positions.query';
import { TCPosition } from './../positions/position.model';
import { EventsQuery } from './../events/events.query';
import { TCVisitFull } from './../visits/visit.model';
import { Query } from '@datorama/akita';
import { Fod2Store, Fod2State } from './fod2.store';
import { Injectable } from '@angular/core';
import { DevicesQuery } from '../devices/devices.query';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TCDevice } from '../devices/device.model';
import { VisitsQuery } from '../visits/visits.query';
import { TCEvent } from '../events/event.model';
import { TCDriver } from '../drivers/driver.model';
import { DriversQuery } from '../drivers/drivers.query';

export interface DeviceAndVisit {
  device: TCDevice;
  visit?: TCVisitFull;
  position?: TCPosition;
  visitor?: TCDriver;
}

@Injectable({ providedIn: 'root' })
export class Fod2Query extends Query<Fod2State> {
  constructor(
    protected override store: Fod2Store,
    protected devicesQuery: DevicesQuery,
    protected visitsQuery: VisitsQuery,
    protected eventsQuery: EventsQuery,
    protected driversQuery: DriversQuery,
    protected positionsQuery: PositionsQuery
  ) {
    super(store);
  }

  public get loading$(): Observable<boolean> {
    return combineLatest([
      this.devicesQuery.selectLoading(),
      this.visitsQuery.selectLoading(),
      this.eventsQuery.selectLoading(),
      this.driversQuery.selectLoading(),
      this.positionsQuery.selectLoading(),
    ]).pipe(
      map(([dev, vis, ev, dr, pos]) => {
        return dev || vis || ev || dr || pos;
      })
    );
  }

  public get visitsToShow$(): Observable<DeviceAndVisit[]> {
    return combineLatest([
      this.activeVisit$,
      this.activeDevice$,
      this.allDevices$,
    ]).pipe(
      map(([visit, device, allDevices]) => {
        return !!visit
          ? [visit]
          : !!device
          ? [device]
          : allDevices.filter((d) => !!d.visit);
      })
    );
  }

  public get activeEvent$(): Observable<TCEvent | undefined> {
    return this.eventsQuery.selectActive();
  }

  protected get activeVisit$(): Observable<DeviceAndVisit | undefined> {
    return combineLatest([
      this.visitsQuery.selectActive(),
      this.positionsQuery.selectAll(),
      this.devicesQuery.selectAll(),
      this.driversQuery.selectAll(),
    ]).pipe(
      map(([visit, positions, devices, drivers]) => {
        if (!visit) return undefined;

        return this.getDevice(visit, positions, devices, drivers);
      })
    );
  }

  public get allCurrentVisits$(): Observable<DeviceAndVisit[]> {
    return combineLatest([
      this.devicesQuery.selectAll(),
      this.positionsQuery.selectAll(),
      this.visitsQuery.selectCurrentVisits(),
      this.driversQuery.selectAll(),
    ]).pipe(
      map(([allDevices, positions, visits, drivers]) => {
        const devices = visits
          .map((visit) => {
            return this.getDevice(visit, positions, allDevices, drivers);
          })
          .filter((x) => !!x)
          .map((x) => x!);

        return devices;
      })
    );
  }

  public get allVisits$(): Observable<DeviceAndVisit[]> {
    return combineLatest([
      this.devicesQuery.selectAll(),
      this.positionsQuery.selectAll(),
      this.visitsQuery.selectAll(),
      this.driversQuery.selectAll(),
    ]).pipe(
      map(([allDevices, positions, visits, drivers]) => {
        const devices = visits
          .map((visit) => {
            return this.getDevice(visit, positions, allDevices, drivers);
          })
          .filter((x) => !!x)
          .map((x) => x!);

        return devices;
      })
    );
  }

  protected get visits$(): Observable<DeviceAndVisit[]> {
    return combineLatest([this.activeVisit$, this.allVisits$]).pipe(
      map(([visit, allVisits]) => {
        const visits = !!visit ? [visit] : allVisits;
        return visits;
      })
    );
  }

  protected get activeDevice$(): Observable<DeviceAndVisit | undefined> {
    return combineLatest([
      this.devicesQuery.selectActive(),
      this.positionsQuery.selectAll(),
      this.visitsQuery.selectAll(),
      this.driversQuery.selectAll(),
    ]).pipe(
      map(([device, positions, visits, drivers]) => {
        return this.getVisit(device, positions, visits, drivers);
      })
    );
  }

  protected get allDevices$(): Observable<DeviceAndVisit[]> {
    return combineLatest([
      this.devicesQuery.selectAll(),
      this.positionsQuery.selectAll(),
      this.visitsQuery.selectAll(),
      this.driversQuery.selectAll(),
    ]).pipe(
      map(([allDevices, positions, visits, drivers]) => {
        const devices = allDevices
          .map((dev) => {
            return this.getVisit(dev, positions, visits, drivers)!;
          })
          .filter((x) => !!x)
          .map((x) => x!);

        return devices;
      })
    );
  }

  protected get devices$(): Observable<DeviceAndVisit[]> {
    return combineLatest([this.activeDevice$, this.allDevices$]).pipe(
      map(([device, allDevices]) => {
        const devices = !!device ? [device] : allDevices;
        return devices;
      })
    );
  }

  protected getDevice(
    visit: TCVisitFull,
    positions: TCPosition[],
    allDevices: TCDevice[],
    drivers: TCDriver[]
  ): DeviceAndVisit | undefined {
    if (!visit) return undefined;

    const device = allDevices.find((d) => d.id === visit.deviceId);
    if (!device) return undefined;

    const pos = positions.find((p) => device.positionId == p.id);
    const driver = drivers.find((dr) => visit.driverId === dr.id);

    return {
      device: device,
      position: pos,
      visit: visit,
      visitor: driver,
    };
  }

  protected getVisit(
    device: TCDevice | undefined,
    positions: TCPosition[],
    visits: TCVisitFull[],
    drivers: TCDriver[]
  ): DeviceAndVisit | undefined {
    if (!device) return undefined;

    const pos = positions.find((p) => device.positionId == p.id);
    const visit = visits.find((v) => v.deviceId === device.id && !v.endTime);

    const driver = !!visit
      ? drivers.find((dr) => visit.driverId === dr.id)
      : undefined;
    return { device: device, position: pos, visit: visit, visitor: driver };
  }
}
