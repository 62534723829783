import { QueryEntity } from '@datorama/akita';
import { PositionsStore, PositionsState } from './positions.store';
import { Injectable } from '@angular/core';

@Injectable( { providedIn: 'root' } )
export class PositionsQuery extends QueryEntity<PositionsState> {

  constructor(protected override store: PositionsStore) {
    super(store);
  }

}
