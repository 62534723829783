import { DriversQuery } from './../../state/drivers/drivers.query';
import { VisitsQuery } from './../../state/visits/visits.query';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
} from '@angular/core';
import { Config, Event, Columns } from 'ngx-easy-table';
import { ConfigService, ServiceLocator } from 'src/app/services';
import { Observable, BehaviorSubject, EMPTY, combineLatest } from 'rxjs';
import {
  TCEvent,
  EventFilter,
  EventsService,
  EventsQuery,
  DriversService,
  DevicesService,
  DevicesQuery,
  EventWithVisitorDeviceVisit,
} from 'src/app/state';
import { isNil } from '@datorama/akita';
import { map, debounceTime, switchMap, finalize, first } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as moment from 'moment';

@Component({
  selector: 'fod2-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit, OnDestroy {
  @ViewChild('actionsColumn', { static: true })
  private actionsColumnRef!: TemplateRef<any>;

  @ViewChild('eventTimeColumn', { static: true })
  private eventTimeColumnRef!: TemplateRef<any>;

  @Input()
  public configuration: Config = {
    ...ConfigService.config,
    threeWaySort: false,
    selectRow: true,
    tableLayout: {
      ...ConfigService.config.tableLayout,
      borderless: false,
    },
  };

  @Input()
  public columns: Columns[] = [];

  @Output()
  public eventSelected = new EventEmitter<EventWithVisitorDeviceVisit>();

  public locale: string;

  public rows$: Observable<any> = EMPTY;

  protected filter: EventFilter = {};
  protected filterSubj = new BehaviorSubject<EventFilter>({});

  constructor(
    protected eventsSvc: EventsService,
    private eventsQuery: EventsQuery,
    protected driversSvc: DriversService,
    protected devicesSvc: DevicesService
  ) {
    this.locale = ServiceLocator.injector.get(LOCALE_ID);
  }

  ngOnInit() {
    this.columns = [
      // { key: 'id', title: 'Id', placeholder: 'Search', width: '20px' },
      {
        key: 'device.name',
        title: 'Device',
        placeholder: 'Search',
        width: '15%',
      },
      {
        key: 'visitor.name',
        title: 'Visitor',
        placeholder: 'Search',
        width: '30%',
      },
      { key: 'type', title: 'Type', placeholder: 'Search', width: '20%' },
      {
        key: 'eventTime',
        title: 'Time',
        searchEnabled: false,
        cellTemplate: this.eventTimeColumnRef,
        width: '30%',
        orderBy: 'desc',
      },
      {
        key: 'id',
        title: '',
        searchEnabled: false,
        cellTemplate: this.actionsColumnRef,
        width: '5%',
      },
    ];

    this.rows$ = this.eventsQuery.selectFiltered();

    this.filterSubj
      .asObservable()
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((f) => this.eventsSvc.updateFilter(f));
  }

  ngOnDestroy() {}

  public onEvent($event: any) {
    if ($event.event === Event.onClick) {
      const event = $event.value.row;
      this.eventSelected.emit(event);
    }
  }

  public onFilterSearch(
    what: keyof EventFilter,
    value: moment.Moment & string
  ) {
    this.filter[what] = value;
    this.filterSubj.next(this.filter);
  }

  public onDateSearch(value: moment.Moment) {
    this.filter = { ...this.filter, selectedDate: value };
    this.filterSubj.next(this.filter);
  }

  public onTypeSearch(value: EventTarget | null) {
    this.filter = {
      ...this.filter,
      type: (value as HTMLTextAreaElement)?.value,
    };
    this.filterSubj.next(this.filter);
  }

  public onDeviceIdSearch(value: EventTarget | null) {
    this.filter = {
      ...this.filter,
      deviceName: (value as HTMLTextAreaElement)?.value,
    };
    this.filterSubj.next(this.filter);
  }

  public onDriverIdSearch(value: EventTarget | null) {
    // this.onFilterSearch( 'visitorName', (value as HTMLTextAreaElement)?.value);
    this.filter = {
      ...this.filter,
      visitorName: (value as HTMLTextAreaElement)?.value,
    };
    this.filterSubj.next(this.filter);
  }

  public onPositionEvent(row: TCEvent) {}
}
