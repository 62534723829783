import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { DevicesQuery, TCDevice, DevicesService } from 'src/app/state';
import { Observable, EMPTY } from 'rxjs';
import { DeviceFormComponent } from '../device-form/device-form.component';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { isNil } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { sanitizeError, confirm } from 'src/app/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ServiceLocator } from 'src/app/services';
import { MapComponent } from 'src/app/map/map.component';

@Component({
  selector: 'fod2-devices-overview',
  templateUrl: './devices-overview.component.html',
  styleUrls: ['./devices-overview.component.scss'],
})
export class DevicesOverviewComponent implements OnInit, OnDestroy {
  protected snackBar: MatSnackBar;
  protected dialog: MatDialog;

  public devices$: Observable<TCDevice[]>;

  @Input()  
  public map!: MapComponent;
  
  @Output()
  public deviceSelected = new EventEmitter<TCDevice>();

  constructor(
    protected devicesQuery: DevicesQuery,
    protected devicesSvc: DevicesService
  ) {
    this.snackBar = ServiceLocator.injector.get(MatSnackBar);
    this.dialog = ServiceLocator.injector.get(MatDialog);

    this.devices$ = this.devicesQuery.selectAll().pipe(
      tap((d) => {
        console.log('Devices: ', d);
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {}

  public addDevice(): void {
    const dialogRef = this.dialog.open(DeviceFormComponent, {
      width: 'auto',
      data: null,
    });

    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((device) => {
          console.log('Create new device: ', device);
          if (isNil(device)) return EMPTY;
          delete device.id;

          const obs$ = this.devicesSvc
            .save(device)
            .pipe(switchMap((_) => this.devicesSvc.load()));

          return obs$;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          console.log(`The device was saved`);
        },
        (e) => {
          if (!isNil(e)) {
            this.snackBar.open(sanitizeError(e), undefined, {
              duration: 5000,
              panelClass: 'snackbar-error',
            });
          }
        }
      );
  }

  public editDevice(dev: any) {
    const dialogRef = this.dialog.open(DeviceFormComponent, {
      width: 'auto',
      data: dev,
    });

    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((device) => {
          console.log('Edit device: ', device);
          const obs$ = isNil(device)
            ? EMPTY
            : this.devicesSvc
                .save(device)
                .pipe(switchMap((_) => this.devicesSvc.load()));

          return obs$;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (v) => {
          console.log(`The device was saved`);
        },
        error: (e) => {
          if (!isNil(e)) {
            this.snackBar.open(sanitizeError(e), undefined, {
              duration: 5000,
              panelClass: 'snackbar-error',
            });
          }
        },
      });
  }

  public deleteDevice(device: TCDevice) {
    confirm(
      this,
      `Confirmation`,
      `Are you sure you want to delete device: ${device.name}`,
      () => {
        this.devicesSvc
          .delete(device)
          .pipe(
            untilDestroyed(this),
            switchMap((_) => this.devicesSvc.load())
          )
          .subscribe({
            next: (v) => {
              console.log(`The device was deleted`);
            },
            error: (e) => {
              if (!isNil(e)) {
                this.snackBar.open(sanitizeError(e), undefined, {
                  duration: 5000,
                  panelClass: 'snackbar-error',
                });
              }
            },
          });
      }
    );
  }

  public onDeviceSelected(device: TCDevice) {
    this.deviceSelected.emit(device);
  }
}
