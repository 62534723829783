<ngx-table [id]="'eventsList'" [configuration]="configuration" [data]="(rows$ | async)" [columns]="columns" (event)="onEvent($event)"
    [filtersTemplate]="filtersTemplate"></ngx-table>

<ng-template #actionsColumn let-row>
    <div class="action-cell">
        <button mat-icon-button color="primary" (click)="onPositionEvent( row)" type="button" [disabled]="!row.positionId"
            matTooltip="Event position">
            <mat-icon fontSet="fas" fontIcon="fa-crosshairs" class="mat-24"></mat-icon>
        </button>
    </div>
</ng-template>


<ng-template #eventTimeColumn let-row>
    <div>{{row.eventTime | date:'medium':locale}}</div>
</ng-template>

<ng-template #filtersTemplate>
    <th>
        <input class="form-input" (input)="onDeviceIdSearch($event.target)" type="text" id="filter-deviceId"
            placeholder="Device..."  matTooltip="Filter by device">
    </th>
    <th>
        <input class="form-input" (input)="onDriverIdSearch($event.target)" type="text" id="filter-driverId"
            placeholder="Visitor..."  matTooltip="Filter by visitor">
    </th>
    <th>
        <input class="form-input" (input)="onTypeSearch($event.target)" type="text" id="filter-visitor"
            placeholder="Event type..."  matTooltip="Filter by visitor">
    </th>
    <th>
        <input class="calendar-input" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" 
            (dateTimeInput)="onDateSearch($event.value)" matTooltip="Filter by date">
        <span class="trigger" [owlDateTimeTrigger]="dt1">
            <i class="fas fa-calendar-alt"></i>
        </span>
        <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
    </th>
    <th></th>
</ng-template>