import { QueryEntity, isNil, HashMap } from '@datorama/akita';
import { VisitsStore, VisitsState } from './visits.store';
import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, combineLatest, EMPTY } from 'rxjs';
import { TCVisitFull } from './visit.model';
import * as moment from 'moment';
import { DriversQuery } from '../drivers/drivers.query';
import { TCDriver } from '../drivers/driver.model';
import { TCDevice } from '../devices/device.model';
import { DevicesState } from '../devices/devices.store';
import { ServiceLocator } from 'src/app/services';
import { TCEvent } from '../events/event.model';

@Injectable({ providedIn: 'root' })
export class VisitsQuery extends QueryEntity<VisitsState> {
  protected _devicesQuery?: QueryEntity<DevicesState> = undefined;

  protected get devicesQuery(): QueryEntity<DevicesState> {
    if (!this._devicesQuery) {
      this._devicesQuery = ServiceLocator.injector.get('DevicesQueryInj');
    }
    return this._devicesQuery!;
  }

  constructor(
    protected override store: VisitsStore,
    protected driversQuery: DriversQuery
  ) // Avoid circular reference with DevicesQuery
  // @Inject('DevicesQueryInj') protected devicesQuery: QueryEntity<DevicesState>
  {
    super(store);
  }

  public selectOngoing(): Observable<TCVisitFull[]> {
    return this.selectAll({ filterBy: (v) => !v.endTime });
  }

  public getOngoing(): TCVisitFull[] {
    return this.getAll({ filterBy: (v) => !v.endTime });
  }

  public selectCurrentVisits(): Observable<TCVisitFull[]> {
    return this.selectAll().pipe(map((vs) => vs.filter((v) => !v.endTime)));
  }

  public selectFiltered(): Observable<TCVisitFull[]> {
    // const devices$ = new Observable<TCDevice[]>((subscriber) => subscriber.complete()); //this.devicesQuery?.selectAll();
    const devices$ = this.devicesQuery?.selectAll();
    const drivers$ = this.driversQuery.selectAll();
    const visits$ = this.selectAll();
    return combineLatest([
      this.select((st) => st.filter),
      visits$,
      drivers$,
      devices$,
    ]).pipe(
      map(([f, vs, dr, de]) => {
        if (isNil(f)) {
          return vs;
        }

        let drivers: TCDriver[];
        if (isNil(f.visitorName)) {
          drivers = dr;
        } else {
          const visitorName = f.visitorName!.toLowerCase();
          drivers = dr.filter((d) => {
            return -1 !== d.name.toLowerCase().indexOf(visitorName);
          });
        }

        let devices: TCDevice[];
        if (isNil(f.deviceName)) {
          devices = de;
        } else {
          const deviceName = f.deviceName!.toLowerCase();
          devices = de.filter((d) => {
            return -1 !== d.name.toLowerCase().indexOf(deviceName);
          });
        }

        return vs.filter((v) => {
          if (!isNil(f.selectedDate)) {
            const start = moment(v.startTime);
            const end = moment(v.endTime);
            if (start.isValid() && start.startOf('day') > f.selectedDate!) {
              return false;
            }
            if (end.isValid() && end.endOf('day') < f.selectedDate!) {
              return false;
            }
          }

          return (
            !isNil(drivers.find((d) => d.id === v.driverId)) &&
            !isNil(devices.find((d) => d.id === v.deviceId))
          );
        });
      })
    );
  }
}
