<div class="list-wrapper">
	<mat-form-field>
		<mat-label>Devices</mat-label>
		<mat-select [formControl]="devices" [multiple]="true">
			<mat-option>
				<ngx-mat-select-search [formControl]="deviceFilterCtrl" [placeholderLabel]="'Search device...'"
					[noEntriesFoundLabel]="'No matching devices found'">
					<mat-icon ngxMatSelectSearchClear fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
				</ngx-mat-select-search>
			</mat-option>
			<mat-option *ngFor="let dev of (devices$ | async)" [value]="dev">{{dev.name}}</mat-option>
		</mat-select>
	</mat-form-field>
</div>